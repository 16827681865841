import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { DialogProvider } from "@hiyllo/ux/dialogs";
import { WebPortalsProvider } from "@hiyllo/ux/web-portals";
import { FiberProvider } from "its-fine";
import { UXThemeProvider } from "./providers/theme-provider";
import "./index.css";
import { AuthenticationProvider } from "./providers/authentication-provider";
import { TenantProvider } from "./providers/tenant-provider";
import { HeaderContentProvider } from "./providers/header-content-provider";
import { AlertProvider } from "./providers/alert-provider";
import { FontAwesomeIconCtx } from "@hiyllo/ux/platform";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  /* @ts-expect-error --- */
  <FontAwesomeIconCtx.Provider value={FontAwesomeIcon}>
    <FiberProvider>
      <UXThemeProvider>
        <WebPortalsProvider>
          <DialogProvider>
            <AuthenticationProvider>
              <TenantProvider>
                <HeaderContentProvider>
                  <AlertProvider>
                    <App />
                  </AlertProvider>
                </HeaderContentProvider>
              </TenantProvider>
            </AuthenticationProvider>
          </DialogProvider>
        </WebPortalsProvider>
      </UXThemeProvider>
    </FiberProvider>
  </FontAwesomeIconCtx.Provider>
);

import React from "react";
import { useParams } from "react-router-dom";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons";

import * as GetConversationBP from "../../../../blueprints/admin/conversations/get-conversation";
import * as ReplyToConversationBP from "../../../../blueprints/admin/conversations/reply-to-conversation";
import * as GetAgentNameBp from "../../../../blueprints/util/get-name-by-hiylloid";
import * as CreateFileUploadBP from "../../../../blueprints/fs/create-file-upload";

import { client } from "../../../../singletons/moopsy-client";
import { styled } from "@hiyllo/ux/styled";
import { useInputControl } from "@hiyllo/ux/input";
import { useShowAlert } from "@hiyllo/ux/dialogs";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { LoadingSpinnerView } from "@hiyllo/ux/loading-spinner";
import { useTheme } from "@hiyllo/ux/theme";
import { isInternalSender } from "../../../../utils";
import { Header } from "../../components/conversation-header";
import { Footer } from "../../components/conversation-footer";
import { MessageBubble } from "../../components/conversation-bubble";
import {
  Message,
  MessageOrigin,
} from "../../../../types/conversations/message";
import moment from "moment";
import { AnimatePresence, motion } from "framer-motion";
import ChatInformationView from "../../components/chat-info-view";
import { uploadFileToS3 } from "../../../fs";

const Container = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  backgroundColor: $theme.background1,
  color: $theme.foreground,

  overflowX: "hidden",
}));

const MessagesContainer = styled("div", {
  flex: 1,
  padding: "0px 20px",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  height: "calc(100% - 176px)",
});

const MessageGroup = styled("div", {
  display: "flex",
  alignItems: "flex-start",
  gap: "10px",
  width: "100%",
});

const AvatarImg = styled("img", {
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  objectFit: "cover",
});

const MessageContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  width: "100%",
});

const SenderName = styled("div", ({ $theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  gap: "5px",
  fontSize: "16px",
  fontWeight: "bold",
  color: $theme.foreground,
}));

export const ConversationView: React.FC = React.memo(() => {
  const { uuid } = useParams<{ uuid: string }>();
  const showAlert = useShowAlert();
  const theme = useTheme();
  const [messages, setMessages] = React.useState<Message[]>([]);
  const [hoveredMessageId, setHoveredMessageId] = React.useState<string | null>(
    null
  );
  const [agentNames, setAgentNames] = React.useState<Record<string, string>>(
    {}
  );
  const [isSideBarOpen, setIsSideBarOpen] = React.useState<boolean>(false);
  const [attachments, setAttachments] = React.useState<File[]>([]);
  const [attachmentFsIds, setAttachmentFsIds] = React.useState<string[]>([]);
  const [isMessageSending, setIsMessageSending] =
    React.useState<boolean>(false);

  const handleSideBar = React.useCallback(() => {
    setIsSideBarOpen(!isSideBarOpen);
  }, [isSideBarOpen]);

  if (!uuid) {
    throw new Error("Conversation UUID is required");
  }

  const replyInputControl = useInputControl({});
  const conversationQuery = client.useQuery<GetConversationBP.Plug>(
    GetConversationBP,
    { uuid }
  );
  const replyMutation = client.useMutation<ReplyToConversationBP.Plug>(
    ReplyToConversationBP,
    { querySideEffects: [conversationQuery] }
  );
  const getAgentNameMutation =
    client.useMutation<GetAgentNameBp.Plug>(GetAgentNameBp);
  const createFileUploadMutation =
    client.useMutation<CreateFileUploadBP.Plug>(CreateFileUploadBP);

  React.useEffect(() => {
    if (conversationQuery.data) {
      setMessages(conversationQuery.data.messages);
      console.log(JSON.stringify(conversationQuery.data, null, 2));
    }
  }, [conversationQuery.data]);

  React.useEffect(() => {
    if (messages.length === 0) return;

    const uniqueAgentIds = Array.from(
      new Set(
        messages
          .filter((message) => isInternalSender(message.sender))
          .map(
            (message) =>
              message.sender.type === "internal" && message.sender.hiylloID
          )
          .filter((id): id is string => Boolean(id))
      )
    );

    const fetchAgentNames = async () => {
      const agentNamePromises = uniqueAgentIds.map(async (hiylloID) => {
        try {
          const response = await getAgentNameMutation.call({
            hiylloID: hiylloID,
          });
          return { hiylloID, name: response.name };
        } catch {
          return { hiylloID, name: hiylloID };
        }
      });

      const results = await Promise.all(agentNamePromises);
      const namesMap = results.reduce((acc, { hiylloID, name }) => {
        acc[hiylloID] = name;
        return acc;
      }, {} as Record<string, string>);

      setAgentNames(namesMap);
    };

    fetchAgentNames();
  }, [messages]);

  const sendReply = React.useCallback(async () => {
    // No text and no attachments? Then do nothing
    if (!replyInputControl.value.trim() && attachments.length === 0) return;

    setIsMessageSending(true);
    try {
      // 1) We’ll create & upload each attachment to S3, storing their fsIds in "newFsIds"
      const newFsIds: string[] = [];

      for (const file of attachments) {
        // a) create placeholder in your backend
        const createRes = await createFileUploadMutation.call({
          name: file.name,
          mimeType: file.type,
          extension: file.name.split(".").pop() || "",
        });

        // b) upload the file bits to S3
        await uploadFileToS3(
          new File([file], file.name),
          createRes.postOpts,
          () => {}
        );

        // c) store the fsId so we can attach it to the message
        newFsIds.push(createRes.fsId);
      }

      // 2) Now do the actual reply with the new fsIds
      await replyMutation.call({
        conversationUUID: uuid,
        content: replyInputControl.value,
        attachmentFsIds: newFsIds,
      });

      // 3) Clear everything
      replyInputControl.update("");
      setAttachments([]);
      setAttachmentFsIds([]);
    } catch (err) {
      showAlert({
        title: "Failed to Send Reply",
        message:
          err instanceof Error ? err.message : "An unknown error occurred",
      });
    } finally {
      setIsMessageSending(false);
    }
  }, [
    uuid,
    attachments,
    replyInputControl,
    createFileUploadMutation,
    replyMutation,
    showAlert,
  ]);

  const messagesEndRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  if (conversationQuery.isLoading) {
    return <LoadingSpinnerView />;
  }

  if (conversationQuery.isError) {
    return (
      <EmptySplash
        icon={faExclamationCircle}
        label="Failed to Load Conversation"
        hint={conversationQuery.error.message}
      />
    );
  }

  const { conversation } = conversationQuery.data;

  const groupedMessages = messages.reduce((acc, message) => {
    const lastGroup = acc[acc.length - 1];
    if (
      lastGroup &&
      lastGroup.sender.type === message.sender.type &&
      (isInternalSender(lastGroup.sender) && isInternalSender(message.sender)
        ? lastGroup.sender.hiylloID === message.sender.hiylloID
        : !isInternalSender(lastGroup.sender) &&
          !isInternalSender(message.sender) &&
          lastGroup.sender.email === message.sender.email)
    ) {
      lastGroup.messages.push(message);
    } else {
      acc.push({ sender: message.sender, messages: [message] });
    }
    return acc;
  }, [] as { sender: any; messages: any[] }[]);

  return (
    <Container>
      <AnimatePresence>
        <div style={{ height: "100%", display: "flex" }}>
          <div style={{ flex: 1, width: "100%" }}>
            <Header
              subject={conversation.subject}
              isSideBarOpen={isSideBarOpen}
              handleSideBar={handleSideBar}
            />
            <MessagesContainer>
              {groupedMessages.map((group, index) => (
                <MessageGroup key={index}>
                  <AvatarImg
                    src={
                      isInternalSender(group.sender)
                        ? "https://i.pinimg.com/736x/c0/74/9b/c0749b7cc401421662ae901ec8f9f660.jpg"
                        : "https://archive.org/download/twitter-default-pfp/e.png"
                    }
                    alt={group.sender.hiylloID || group.sender.email}
                  />
                  <MessageContent>
                    <SenderName>
                      {isInternalSender(group.sender)
                        ? agentNames[group.sender.hiylloID] ||
                          group.sender.hiylloID
                        : group.sender.email}
                      <span
                        style={{ fontSize: "12px", verticalAlign: "middle" }}
                      >
                        •
                      </span>
                      <span
                        style={{
                          fontSize: "12px",
                          color: theme.foreground,
                          opacity: 0.7,
                        }}
                      >
                        {moment(group.messages[0].date).format(
                          "MMM D, YYYY h:mm A"
                        )}
                      </span>
                      <span
                        style={{ fontSize: "12px", verticalAlign: "middle" }}
                      >
                        •
                      </span>
                      <span style={{ fontSize: "12px" }}>
                        via
                        {group.messages[0].origin === MessageOrigin.Email
                          ? "Email"
                          : group.messages[0].origin ===
                            MessageOrigin.SelfService
                          ? "Support Page"
                          : "Unknown"}
                      </span>
                    </SenderName>

                    {group.messages.map((message) => (
                      <MessageBubble
                        key={`${message.uuid}-${index}`}
                        message={message}
                        isHovered={hoveredMessageId === message.uuid}
                        onHover={setHoveredMessageId}
                        conversation={conversation}
                      />
                    ))}
                  </MessageContent>
                </MessageGroup>
              ))}
              <div ref={messagesEndRef} />
            </MessagesContainer>
          </div>
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: isSideBarOpen ? "50%" : 0 }}
            exit={{ width: 0, opacity: 0 }}
            transition={{ duration: 0.5 }}
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            {isSideBarOpen && (
              <ChatInformationView conversation={conversation} />
            )}
          </motion.div>
        </div>
      </AnimatePresence>
      <Footer
        sendReply={sendReply}
        replyInputControl={replyInputControl}
        attachments={attachments}
        setAttachments={setAttachments}
        isMessageSending={isMessageSending}
      />
    </Container>
  );
});

/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  uuid: string;
  title?: string;
  description?: string;
  articleUuid?: string;
  icon?: string;
  published?: boolean;
  articles?: string[];
};
export type ResponseType = {};
export const Endpoint = "section/update-section";
export const Method = "POST";
export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const RateLimitingConfig = {
  calls: 2,
  per: 2500,
};
export const paramsSchema = {"type":"object","properties":{"uuid":{"type":"string"},"title":{"type":"string"},"description":{"type":"string"},"articleUuid":{"type":"string"},"icon":{"type":"string"},"published":{"type":"boolean"},"articles":{"type":"array","items":{"type":"string"}}},"required":["uuid"],"$schema":"http://json-schema.org/draft-07/schema#"}
import React from "react";
import { useTheme } from "@hiyllo/ux/theme";
import { useNavigate } from "react-router-dom";
import { authExtension } from "../../../../../singletons/moopsy-client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/pro-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import { useTenant } from "../../../../../providers/tenant-provider";
import { DefaultRolesEnum } from "../../../../../types/users/roles";
import { Button, ButtonVariant } from "@hiyllo/ux/button";
import { useIsInternal } from "../../../../../shared/hooks/use-is-internal";

export const Navbar = React.memo((props: { transparent?: boolean }) => {
  const [currentTheme, setCurrentTheme] = React.useState(
    localStorage.getItem("theme") || "light"
  );
  const tenant = useTenant();
  const theme = useTheme();
  const navigate = useNavigate();
  const isInternal = useIsInternal();
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const authState = authExtension.useAuthState();

  const toggleTheme = () => {
    const newTheme = currentTheme === "light" ? "dark" : "light";
    setCurrentTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    window.location.reload();
  };

  const handleProfileClick = () => {
    if (authState == null) {
      navigate("/login");
    } else {
      setDropdownOpen((prev) => !prev);
    }
  };

  return (
    <div
      style={{
        height: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "transparent",
        zIndex: 10,
        flexShrink: 0,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
      >
        <img
          src="https://cdn.hiyllo.net/logo/hiyllo/icon-gradient.png"
          alt="Logo"
          style={{ height: 35 }}
        />
        <h1 style={{ margin: 0, color: theme.foreground, fontSize: 30 }}>
          {tenant.appearance.name}
        </h1>
      </div>

      <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <button
          onClick={toggleTheme}
          style={{
            border: "none",
            background: "none",
            cursor: "pointer",
            color: theme.midground2,
            fontSize: "24px",
          }}
        >
          <FontAwesomeIcon icon={currentTheme === "light" ? faMoon : faSun} />
        </button>

        {window.location.pathname !== "/contact-support" ? (
          <Button
            label="Contact Support"
            isSecondary={true}
            onClick={() => navigate("/contact-support")}
            variant={ButtonVariant.narrow}
          />
        ) : null}

        <div style={{ position: "relative" }}>
          <div
            style={{
              overflow: "hidden",
              borderRadius: "50px",
              cursor: "pointer",
            }}
          >
            {authState == null ? (
              <Button
                onClick={handleProfileClick}
                label={"Sign In"}
                style={{ background: theme.midground }}
              />
            ) : (
              <img
                src="https://braverplayers.org/wp-content/uploads/2022/09/braver-blank-pfp.jpg"
                alt="Profile"
                style={{
                  width: "35px",
                  height: "35px",
                  borderRadius: 50,
                  objectFit: "cover",
                }}
                onClick={handleProfileClick}
              />
            )}
          </div>

          <AnimatePresence>
            {dropdownOpen && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
                style={{
                  position: "absolute",
                  top: "50px",
                  right: "0",
                  backgroundColor: theme.background2,
                  boxShadow: `0 0px 1px ${theme.midground1}`,
                  zIndex: 1,
                  borderRadius: "5px",
                  width: "150px",
                }}
              >
                {isInternal && (
                  <div
                    onClick={() => navigate("/admin/dashboard")}
                    style={{
                      padding: "10px 20px",
                      cursor: "pointer",
                      color: theme.foreground,
                    }}
                  >
                    Admin View
                  </div>
                )}
                <div
                  style={{
                    padding: "10px 20px",
                    cursor: "pointer",
                    color: theme.foreground,
                  }}
                  onClick={() => {
                    authExtension.logout();
                    localStorage.removeItem("loginToken");
                    window.location.reload();
                  }}
                >
                  Logout
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
});

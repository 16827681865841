import { Button } from "@hiyllo/ux/button";
import { Input } from "@hiyllo/ux/input";
import { useTheme } from "@hiyllo/ux/theme";
import React from "react";
import { Section } from "../../../../../../types/knowledge-base/section";
import { styled } from "@hiyllo/ux/styled";
import { IconPicker } from "@hiyllo/ux/icon-picker";

interface EditingSectionCardProps {
  editingSection: Section;
  setEditingSection: React.Dispatch<React.SetStateAction<Section | null>>;
  handleUpdateSection: () => void;
}

const Container = styled("div", {
  padding: "20px",
});

const Row = styled("div", {
  display: "flex",
  gap: "15px",
  zIndex: 100,
});

const IconInputContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

const Actions = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  gap: "12px",
});

export const EditingSectionCard: React.FC<EditingSectionCardProps> = React.memo(
  (props) => {
    const theme = useTheme();
    return (
      <Container>
        <h3
          style={{
            color: theme.foreground,
            marginBottom: "20px",
            fontSize: "22px",
            marginTop: 0,
          }}
        >
          Edit your section
        </h3>
        <Row>
          <IconInputContainer>
            <IconPicker
              onSelect={(icon) => {
                props.setEditingSection({
                  ...props.editingSection,
                  icon: icon,
                });
              }}
              selectedIcon={props.editingSection.icon || "question"}
            />
          </IconInputContainer>
          <Input
            label="Title"
            fullWidth={true}
            placeholder="Title"
            value={props.editingSection.title}
            onChange={(e) =>
              props.setEditingSection({
                ...props.editingSection,
                title: e.target.value,
              })
            }
          />
        </Row>
        <Input
          label="Description"
          fullWidth={true}
          placeholder="A description should go here..."
          multiline={true}
          value={props.editingSection.description}
          onChange={(e) =>
            props.setEditingSection({
              ...props.editingSection,
              description: e.target.value,
            })
          }
          outerStyle={{ margin: "16px 0px 16px 0px" }}
        />
        <Actions>
          <Button
            onClick={() => {
              props.setEditingSection(null);
            }}
            style={{ background: theme.midground1 }}
            label="Cancel"
          />
          <Button
            onClick={() => {
              props.handleUpdateSection();
            }}
            style={{ background: theme.colorSubtleAccent }}
            label="Save"
          />
        </Actions>
      </Container>
    );
  }
);

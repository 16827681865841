import React from "react";
import * as GetPaginatedConversationsBP from "../../../../blueprints/admin/conversations/get-paginated-conversations";
import * as ToggleConversationStatusBP from "../../../../blueprints/admin/conversations/toggle-conversation-status";
import * as ToggleMultiConversationsStatusBP from "../../../../blueprints/admin/conversations/toggle-multi-conversations-status";
import { client } from "../../../../singletons/moopsy-client";
import { useNavigate } from "react-router-dom";
import {
  Conversation,
  ConversationStatusEnum,
} from "../../../../types/conversations/conversation";
import {
  TableComponent,
  ColumnDefinition,
} from "../../../../features/knowledge-base/views/admin/components/article/table";
import { MultiSelectActionBar } from "../../../knowledge-base/components/multi-select-action-bar";
import { styled } from "@hiyllo/ux/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingHand,
  faLock,
  faLockOpen,
  faPlus,
  faSearch,
} from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@hiyllo/ux/theme";
import { Button } from "@hiyllo/ux/button";
import { CreateConversation } from "../../components/create-conversation";
import { PaginationControls } from "../../../../components/pagination-contols";

const Container = styled("div", {
  margin: "20px",
  borderRadius: "8px",
  overflowY: "auto",
});

const Header = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "16px",
});

const SearchBar = styled("div", (props) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: props.$theme.background3,
  borderRadius: "20px",
  padding: "8px 16px",
  width: "300px",
  color: props.$theme.foreground,
}));

const Options = styled("div", {
  display: "flex",
  gap: "8px",
});

const SearchInput = styled("input", (props) => ({
  border: "none",
  background: "transparent",
  marginLeft: "8px",
  fontSize: "16px",
  width: "100%",
  color: props.$theme.foreground,
  outline: "none",
}));

export const ConversationsList = React.memo(
  function ConversationsList(): JSX.Element {
    const theme = useTheme();
    const navigate = useNavigate();
    const [pageNumber, setPageNumber] = React.useState(1);
    const [pageSize] = React.useState(15);
    const [selectedTickets, setSelectedTickets] = React.useState<
      Conversation[]
    >([]);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [showCreateTicketModal, setShowCreateTicketModal] =
      React.useState(false);

    const conversationsQuery =
      client.useQuery<GetPaginatedConversationsBP.Plug>(
        GetPaginatedConversationsBP,
        { pageNumber, pageSize }
      );

    const totalConversations = conversationsQuery.data
      ? conversationsQuery.data.totalConversations
      : 0;
    const totalPages = Math.ceil(totalConversations / pageSize);

    const toggleConversationStatusMutation =
      client.useMutation<ToggleConversationStatusBP.Plug>(
        ToggleConversationStatusBP,
        {
          querySideEffects: [conversationsQuery],
        }
      );
    const toggleMultiConversationsStatus =
      client.useMutation<ToggleMultiConversationsStatusBP.Plug>(
        ToggleMultiConversationsStatusBP,
        {
          querySideEffects: [conversationsQuery],
        }
      );

    // Filter conversations based on the search term
    const filteredConversations = React.useMemo(() => {
      if (!conversationsQuery.data) return [];
      return conversationsQuery.data.conversations.filter((conversation) =>
        conversation.subject.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }, [conversationsQuery.data, searchTerm]);

    const columns: ColumnDefinition<Conversation>[] = [
      {
        key: "subject",
        label: "Subject",
        defaultVisible: true,
        render: (conversation) => (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`conversation/${conversation.uuid}`)}
          >
            {conversation.subject}
          </div>
        ),
      },
      {
        key: "priority",
        label: "Priority",
        sortable: true,
        defaultSortBy: "asc",
        defaultVisible: true,
        render: (conversation) => <div>{conversation.priority}</div>,
      },
      {
        key: "type",
        label: "Type",
        defaultVisible: true,
        render: (conversation) => <div>{conversation.type}</div>,
      },
      {
        key: "status",
        label: "Status",
        defaultVisible: true,
        sortable: true,
        defaultSortBy: "asc",
        render: (conversation) => <div>{conversation.status}</div>,
      },
      {
        key: "external",
        label: "Customer",
        defaultVisible: true,
        render: (conversation) => <div>{conversation.external.email}</div>,
      },
    ];

    return (
      <Container>
        {selectedTickets.length > 0 && (
          <MultiSelectActionBar
            items={selectedTickets}
            selectedCount={selectedTickets.length}
            leftContent={<span>{selectedTickets.length} tickets selected</span>}
            rightContent={
              <>
                <Button
                  isSecondary={true}
                  label="Assign"
                  onClick={() => console.log("Assign to agent WIP")}
                  style={{ height: "35px" }}
                />
                {(() => {
                  const allResolved = selectedTickets.every(
                    (ticket) =>
                      ticket.status === ConversationStatusEnum.resolved
                  );
                  const allOpen = selectedTickets.every(
                    (ticket) => ticket.status === ConversationStatusEnum.open
                  );

                  if (allResolved) {
                    return (
                      <Button
                        isSecondary={true}
                        label={
                          selectedTickets.length === 1
                            ? "Open"
                            : `Open ${selectedTickets.length} tickets`
                        }
                        onClick={() => {
                          if (selectedTickets.length === 1) {
                            toggleConversationStatusMutation
                              .call({
                                conversationUUID: selectedTickets[0].uuid,
                              })
                              .then(() => setSelectedTickets([]));
                          } else {
                            toggleMultiConversationsStatus
                              .call({
                                conversationUUIDs: selectedTickets.map(
                                  (ticket) => ticket.uuid
                                ),
                              })
                              .then(() => setSelectedTickets([]));
                          }
                        }}
                        style={{ height: "35px" }}
                      />
                    );
                  }

                  if (allOpen) {
                    return (
                      <Button
                        isSecondary={true}
                        label={
                          selectedTickets.length === 1
                            ? "Close"
                            : `Close ${selectedTickets.length} tickets`
                        }
                        onClick={() => {
                          if (selectedTickets.length === 1) {
                            toggleConversationStatusMutation
                              .call({
                                conversationUUID: selectedTickets[0].uuid,
                              })
                              .then(() => setSelectedTickets([]));
                          } else {
                            toggleMultiConversationsStatus
                              .call({
                                conversationUUIDs: selectedTickets.map(
                                  (ticket) => ticket.uuid
                                ),
                              })
                              .then(() => setSelectedTickets([]));
                          }
                        }}
                        style={{ height: "35px" }}
                      />
                    );
                  }
                  return;
                })()}
              </>
            }
          />
        )}

        {showCreateTicketModal ? (
          <CreateConversation />
        ) : (
          <div style={{ flex: 1, overflowY: "auto" }}>
            <Header>
              <SearchBar>
                <FontAwesomeIcon icon={faSearch} />
                <SearchInput
                  type="text"
                  placeholder="Search tickets..."
                  value={searchTerm}
                  onChange={(e: {
                    target: { value: React.SetStateAction<string> };
                  }) => setSearchTerm(e.target.value)}
                />
              </SearchBar>
              <Options>
                <Button
                  onClick={() => setShowCreateTicketModal(true)}
                  label={
                    <>
                      <FontAwesomeIcon icon={faPlus} /> New Ticket
                    </>
                  }
                  style={{
                    background: theme.colorSubtleAccent,
                    color: theme.foreground,
                    height: "35px",
                    borderRadius: "20px",
                  }}
                />
              </Options>
            </Header>
            <TableComponent<Conversation>
              data={filteredConversations}
              selectedItems={selectedTickets}
              setSelectedItems={setSelectedTickets}
              columns={columns}
              getRowId={(conversation) => conversation.uuid}
              rowActions={(conversation) => (
                <>
                  <Button
                    label={<FontAwesomeIcon icon={faHandHoldingHand} />}
                    isSecondary={true}
                    onClick={() => console.log("Assign to agent WIP")}
                    style={{
                      borderRadius: "5px 0px 0px 5px",
                    }}
                  />
                  <Button
                    label={
                      conversation.status === ConversationStatusEnum.open ? (
                        <FontAwesomeIcon icon={faLock} />
                      ) : (
                        <FontAwesomeIcon icon={faLockOpen} />
                      )
                    }
                    isSecondary={true}
                    onClick={() =>
                      toggleConversationStatusMutation.call({
                        conversationUUID: conversation.uuid,
                      })
                    }
                    style={{
                      borderRadius: "0px 5px 5px 0px",
                    }}
                  />
                </>
              )}
            />
            <PaginationControls
              totalPages={totalPages}
              setPageNumber={setPageNumber}
              pageNumber={pageNumber}
            />
          </div>
        )}
      </Container>
    );
  }
);

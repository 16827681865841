import React from "react";
import { useNavigate } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { Button } from "@hiyllo/ux/button";
import { Input } from "@hiyllo/ux/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@hiyllo/ux/styled";

const HeaderContainer = styled("div", ({ $theme }) => ({
  padding: "10px 20px",
  backgroundColor: $theme.background1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "60px",
  gap: "10px",

  position: "sticky",
  top: 0,
  zIndex: 100,
}));

const SubjectContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: "10px",
  flex: 1,
  minWidth: "0",
});

const SearchContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  flexShrink: 0,
  transition: "width 0.5s ease-in-out",
  gap: "10px",
});

const RotatingButton = styled("div", {
  transition: "transform 0.5s ease-in-out",
});

const RotatingIcon = styled("div", {
  display: "inline-block",
  transition: "transform 0.5s ease-in-out",
});

export const Header: React.FC<{
  subject: string;
  isSideBarOpen: boolean;
  handleSideBar: () => void;
}> = React.memo(({ subject, isSideBarOpen, handleSideBar }) => {
  const navigate = useNavigate();

  return (
    <HeaderContainer>
      <SubjectContainer>
        <Button
          isSecondary={true}
          onClick={() => navigate("/admin/dashboard/conversations")}
          label={<FontAwesomeIcon icon={faArrowLeft} />}
          style={{ width: 35, height: 35, fontSize: 15 }}
        />
        <p
          style={{
            fontSize: 20,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: isSideBarOpen ? "150px" : "100%",
          }}
        >
          {subject}
        </p>
      </SubjectContainer>
      <SearchContainer
        style={{
          width: isSideBarOpen ? "200px" : "300px",
        }}
      >
        <Input
          placeholder="Search Conversation"
          containerStyle={{ width: "100%" }}
        />
        <RotatingButton>
          <Button
            isSecondary={true}
            onClick={handleSideBar}
            label={
              <RotatingIcon
                style={{
                  transform: isSideBarOpen ? "rotate(180deg)" : "rotate(0deg)",
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </RotatingIcon>
            }
            style={{
              height: 40,
              fontSize: 15,
            }}
          />
        </RotatingButton>
      </SearchContainer>
    </HeaderContainer>
  );
});

/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  conversationUUID: string;
  messageUUID: string;
};
export type ResponseType = void;
export const Endpoint = "admin/conversations/pin-message";
export const Method = "POST";

export type Plug = {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
};
export const paramsSchema = {"type":"object","properties":{"conversationUUID":{"type":"string"},"messageUUID":{"type":"string"}},"required":["conversationUUID","messageUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}
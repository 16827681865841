import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "@hiyllo/ux/tokyo";
import {
  faComment,
  faEnvelope,
  faPaintBrush,
  faPlusCircle,
} from "@fortawesome/pro-light-svg-icons";
import { SupportAdminSidebar } from "../../admin/sidebar";
import { AdminSettingsHome } from "./views/home";
import { AdminSettingsEmail } from "./views/email";
import { AdminAppearanceView } from "./views/appearance";
import { AdminAddAgentsView } from "./views/add-agents";
import { AdminAddBrandFastReplies } from "./views/add-fast-replies";
import { authExtension } from "../../../singletons/moopsy-client";

export const AdminSettingsRouter = React.memo(
  function AdminSettingsRouter(): JSX.Element {
    const authState = authExtension.useAuthState();

    console.log(">>>>" + JSON.stringify(authState, null, 2));

    const location = useLocation();
    const navigate = useNavigate();

    return (
      <>
        <SupportAdminSidebar>
          <Sidebar.Header label="Settings" />

          <Sidebar.Button
            isActive={location.pathname.includes("email")}
            icon={{ fa: faEnvelope }}
            onClick={() => {
              navigate("email");
            }}
            label="Email"
          />
          <Sidebar.Button
            isActive={location.pathname.includes("appearance")}
            icon={{ fa: faPaintBrush }}
            onClick={() => {
              navigate("appearance");
            }}
            label="Appearance"
          />
          <Sidebar.Button
            isActive={location.pathname.includes("add-agents")}
            icon={{ fa: faPlusCircle }}
            onClick={() => {
              navigate("add-agents");
            }}
            label="Invite Agents"
          />
          {authState?.roles.includes("admin") && (
            <Sidebar.Button
              isActive={location.pathname.includes("add-fast-replies")}
              icon={{ fa: faComment }}
              onClick={() => {
                navigate("add-fast-replies");
              }}
              label="Company Fast Replies"
            />
          )}
        </SupportAdminSidebar>
        <div style={{ width: 0, flexGrow: 1 }}>
          <Routes>
            <Route path="email" element={<AdminSettingsEmail />} />
            <Route path="appearance" element={<AdminAppearanceView />} />
            <Route path="add-agents" element={<AdminAddAgentsView />} />
            <Route
              path="add-fast-replies"
              element={<AdminAddBrandFastReplies />}
            />
            <Route path="*" element={<AdminSettingsHome />} />
          </Routes>
        </div>
      </>
    );
  }
);

import { Button, ButtonVariant } from "@hiyllo/ux/button";
import { Input } from "@hiyllo/ux/input";
import { styled } from "@hiyllo/ux/styled";
import { Typography } from "@hiyllo/ux/typography";
import React, { useState } from "react";
import NoContent from "../../../../components/no-content";
import { useShowConfirm, useShowDialog } from "@hiyllo/ux/dialogs";
import { authExtension, client } from "../../../../singletons/moopsy-client";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { hexToRGBA } from "../../../../utils";
import { useAlert } from "../../../../providers/alert-provider";
import * as AddBrandFastReplyBP from "../../../../blueprints/util/add-brand-fast-reply";
import * as ModifyBrandFastReplyBP from "../../../../blueprints/util/modify-brand-fast-reply";
import * as GetBrandFastRepliesBP from "../../../../blueprints/util/get-brand-fast-replies";
import { BrandFastReplyType } from "../../../../types/util/brand-fast-reply";

const Container = styled("div", (props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "97vh",
  padding: 20,
  color: props.$theme.foreground,
}));

const ReplyListItem = styled("div", (props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "5px 10px",
  borderRadius: "8px",
  backgroundColor: hexToRGBA(props.$theme.midground1, 0.3),
  marginBottom: "8px",
}));

const AddReplyContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  marginTop: 20,
  gap: 10,
});

const ButtonWrapper = styled("div", {
  display: "flex",
  gap: 10,
});

export const AdminAddBrandFastReplies = React.memo(
  function AddBrandFastReplies({}): JSX.Element {
    const authState = authExtension.useAuthState();
    const showDialog = useShowDialog();
    const showConfirm = useShowConfirm();
    const { pushAlert } = useAlert();
    const [replies, setReplies] = useState<BrandFastReplyType[] | null>(null);
    const [newReply, setNewReply] = useState("");

    const getBrandFastRepliesQuery =
      client.useQuery<GetBrandFastRepliesBP.Plug>(GetBrandFastRepliesBP, {});

    const addBrandFastReplyMutation =
      client.useMutation<AddBrandFastReplyBP.Plug>(AddBrandFastReplyBP, {
        querySideEffects: [getBrandFastRepliesQuery],
      });

    const modifyBrandFastReplyMutation =
      client.useMutation<ModifyBrandFastReplyBP.Plug>(ModifyBrandFastReplyBP, {
        querySideEffects: [getBrandFastRepliesQuery],
      });

    React.useEffect(() => {
      const allReplies = getBrandFastRepliesQuery.data?.replies;

      if (allReplies !== undefined) {
        setReplies(allReplies);
      }
    }, [getBrandFastRepliesQuery.data]);

    const handleAddReply = React.useCallback(() => {
      if (newReply.trim()) {
        addBrandFastReplyMutation.call({ text: newReply });
        setNewReply("");
      }
    }, [addBrandFastReplyMutation, newReply]);

    const handleEditReply = React.useCallback(
      (uuid: string) => {
        showDialog({
          title: "Edit Reply",
          message: `Modify and then save your changes to the reply.`,
          requireValue: true,
          defaultValue:
            replies?.find((reply) => reply.uuid === uuid)?.text ?? "",
          onSubmit: async (newContent) => {
            if (!newContent) {
              pushAlert("Nothing changed.", "error");
              return;
            }
            try {
              await modifyBrandFastReplyMutation.call({
                uuid,
                newContent,
              });
            } catch (error) {
              pushAlert("An error occurred while editing the reply.", "error");
            }
          },
        });
      },
      [modifyBrandFastReplyMutation, pushAlert, replies, showDialog]
    );

    const handleDeleteReply = React.useCallback(
      (uuid: string) => {
        showConfirm({
          title: "Delete Fast Reply",
          message: "Are you sure you want to delete this fast reply?",
        }).then((confirmed) => {
          if (confirmed) {
            modifyBrandFastReplyMutation.call({ uuid, delete: true });
          }
        });
      },
      [modifyBrandFastReplyMutation, showConfirm]
    );

    if (authState?.roles.includes("admin") === false) {
      return (
        <Container>
          <NoContent plug="You are not authorized to view this page." />
        </Container>
      );
    }

    return (
      <Container>
        <div style={{ marginBottom: 20 }}>
          <Typography.Header>Company preset Fast Replies</Typography.Header>
          <Typography.SubHeader>
            Add and modify the preset fast replies for your agents.
          </Typography.SubHeader>
        </div>
        <div>
          <div>
            {getBrandFastRepliesQuery.isLoading ? (
              <LoadingSpinner />
            ) : !replies || replies.length === 0 ? (
              <NoContent plug="No fast replies to show." />
            ) : (
              replies.map((reply, index) => (
                <ReplyListItem key={index}>
                  <span>{reply.text}</span>
                  <ButtonWrapper>
                    <Button
                      label="Edit"
                      onClick={() => handleEditReply(reply.uuid)}
                      variant={ButtonVariant.narrow}
                      isSecondary
                    />
                    <Button
                      label="Delete"
                      onClick={() => handleDeleteReply(reply.uuid)}
                      style={{
                        background: "rgba(230, 57, 70, 1)",
                      }}
                      variant={ButtonVariant.narrow}
                    />
                  </ButtonWrapper>
                </ReplyListItem>
              ))
            )}
          </div>
          <AddReplyContainer>
            <Input
              multiline
              fullWidth={true}
              type="text"
              placeholder="Add a new fast reply"
              onChange={(e: any) => setNewReply(e.target.value)}
              {...{ value: newReply }}
            />
            <Button onClick={handleAddReply} label="Add" />
          </AddReplyContainer>
        </div>
      </Container>
    );
  }
);

import { Button } from "@hiyllo/ux/button";
import { styled } from "@hiyllo/ux/styled";
import { Typography } from "@hiyllo/ux/typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { hexToRGBA } from "../../../../../utils";

const Container = styled("div", (props) => ({
  marginTop: 50,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  color: props.$theme.foreground,
  textAlign: "center",
  gap: "10px",
}));

const IconWrapper = styled("div", (props) => ({
  fontSize: "48px",
  color: hexToRGBA(props.$theme.foreground ?? "#ffffff", 0.6),
}));

export const NeedMoreHelp = React.memo(function NeedMoreHelp(): JSX.Element {
  const navigate = useNavigate();

  return (
    <Container>
      <IconWrapper>
        <FontAwesomeIcon icon={faQuestionCircle} />
      </IconWrapper>
      <Typography.Header style={{ fontSize: "24px", fontWeight: "bold" }}>
        Need more help?
      </Typography.Header>
      <Typography.Paragraph style={{ fontSize: "16px", opacity: 0.8 }}>
        If you have any questions, please contact our support team. We're here
        to assist you!
      </Typography.Paragraph>
      <Button
        label="Contact Support"
        onClick={() => navigate("/contact-support")}
        style={{
          marginTop: 5,
        }}
      />
    </Container>
  );
});

import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { motion } from "framer-motion";
import { Conversation } from "../../../types/conversations/conversation";
import { Typography } from "@hiyllo/ux/typography";
import { Button } from "@hiyllo/ux/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/pro-light-svg-icons";
import { Select } from "@hiyllo/ux/select";
import * as GetConversationTypesBP from "../../../blueprints/admin/conversations/get-conversation-types";
import * as GetConversationPrioritiesBP from "../../../blueprints/admin/conversations/get-conversation-priorities";
import { client } from "../../../singletons/moopsy-client";

interface ChatInformationViewProps {
  conversation: Conversation;
}

const ChatInformationContainer = styled("div", (props) => ({
  display: "flex",
  flexDirection: "column",
}));

const AppContainer = styled("div", (props) => ({
  display: "flex",
  backgroundColor: props.$theme?.background2,
  flexDirection: "column",
  height: "100%",
  padding: "20px",
}));

const ConversationInfoHeader = styled("div", (props) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
}));

const ConversationOptions = styled("div", (props) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
  marginTop: "10px",
  gap: "10px",
  flexWrap: "wrap",
}));

const AvatarImg = styled("img", {
  width: "45px",
  height: "45px",
  borderRadius: "5px",
  objectFit: "cover",
});

const ConversationInnerContainer = styled("div", {
  marginTop: "15px",
});

export const SlideInFromRight = ({
  children,
  delay = 0,
}: React.PropsWithChildren<{ delay?: number }>): JSX.Element => (
  <motion.div
    initial={{ opacity: 0, x: 20 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.3, delay }}
    exit={{ opacity: 0, x: 20 }}
  >
    {children}
  </motion.div>
);

const ChatInformationView: React.FC<ChatInformationViewProps> = ({
  conversation,
}) => {
  const [selectedType, setSelectedType] = React.useState<string[]>([]);
  const [selectedPriority, setSelectedPriority] = React.useState<string[]>([]);
  const [currentViewState, setCurrentViewState] =
    React.useState("Pinned Messages");

  const viewStates = [
    { label: "Notes", value: "Notes" },
    { label: "Activity Log", value: "Activity Log" },
    { label: "Pinned Messages", value: "Pinned Messages" },
  ];

  const getConversationTypesQuery =
    client.useQuery<GetConversationTypesBP.Plug>(GetConversationTypesBP, {});
  const getConversationPrioritiesQuery =
    client.useQuery<GetConversationPrioritiesBP.Plug>(
      GetConversationPrioritiesBP,
      {}
    );

  const customTypes = React.useMemo(
    () => getConversationTypesQuery.data?.types || [],
    [getConversationTypesQuery.data?.types]
  );

  const customPriorities = React.useMemo(
    () => getConversationPrioritiesQuery.data?.priorities || [],
    [getConversationPrioritiesQuery.data?.priorities]
  );
  const handleTypeSelect = React.useCallback(
    (value: string | number | boolean | null) => {
      const selected = (value as string).split(",");
      setSelectedType(selected || null);
    },
    []
  );

  const handlePrioritySelect = React.useCallback(
    (value: string | number | boolean | null) => {
      const selected = (value as string).split(",");
      setSelectedPriority(selected || null);
    },
    [customPriorities]
  );

  return (
    <AppContainer>
      <ChatInformationContainer>
        <ConversationInfoHeader>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}
            >
              <SlideInFromRight delay={0.3}>
                <AvatarImg
                  src="
              https://archive.org/download/twitter-default-pfp/e.png"
                  alt={conversation.external?.email ?? ""}
                />
              </SlideInFromRight>
              <div>
                <SlideInFromRight delay={0.3}>
                  <Typography.SubHeader>
                    {conversation.subject}
                  </Typography.SubHeader>
                </SlideInFromRight>
                <SlideInFromRight delay={0.3}>
                  <Typography.Paragraph>
                    {conversation.external?.email ?? ""}
                  </Typography.Paragraph>
                </SlideInFromRight>
              </div>
            </div>
          </div>
        </ConversationInfoHeader>
        <ConversationOptions>
          <SlideInFromRight delay={0.5}>
            <Typography.Label>Type</Typography.Label>
            <Select
              value={selectedType.join(",")}
              onChangeValue={handleTypeSelect}
              options={customTypes.map((type) => ({
                label: type.label,
                value: type.key,
              }))}
              fullWidth
              placeholder="None"
              containerStyle={{
                width: "150px",
              }}
            />
          </SlideInFromRight>
          <SlideInFromRight delay={0.7}>
            <Typography.Label>Priority</Typography.Label>

            <Select
              value={selectedPriority.join(",")}
              onChangeValue={handlePrioritySelect}
              options={customPriorities.map((priority) => ({
                label: priority.label,
                value: priority.key,
              }))}
              fullWidth
              placeholder="Select a priority"
              containerStyle={{
                width: "150px",
              }}
            />
          </SlideInFromRight>
          <SlideInFromRight delay={0.9}>
            <Button
              label={
                <>
                  <FontAwesomeIcon icon={faPlus} /> Assign To
                </>
              }
              isSecondary
              onClick={() => console.log("123")}
              style={{ width: "100px" }}
            />
          </SlideInFromRight>
          <SlideInFromRight delay={1.1}>
            <Typography.Label>View</Typography.Label>
            <Select
              value={currentViewState}
              onChangeValue={(value) => setCurrentViewState(value as string)}
              options={viewStates}
              fullWidth
              containerStyle={{
                width: "150px",
              }}
            />
          </SlideInFromRight>
          <SlideInFromRight delay={1.3}>
            <Button
              label={
                <>
                  <FontAwesomeIcon icon={faXmark} /> Close
                </>
              }
              isSecondary
              onClick={() => console.log("123")}
              style={{ width: "100px" }}
            />
          </SlideInFromRight>
        </ConversationOptions>
        <SlideInFromRight delay={1.5}>
          <ConversationInnerContainer>
            {currentViewState === "Pinned Messages" ? (
              <div>
                <Typography.SubHeader>Pinned Messages</Typography.SubHeader>
              </div>
            ) : currentViewState === "Activity Log" ? (
              <div>
                <Typography.SubHeader>Activity Log</Typography.SubHeader>
              </div>
            ) : (
              <div>
                <Typography.SubHeader>Notes</Typography.SubHeader>
              </div>
            )}
          </ConversationInnerContainer>
        </SlideInFromRight>
      </ChatInformationContainer>
    </AppContainer>
  );
};

export default ChatInformationView;

import React from "react";
import { BaseOperation } from "slate";
import { Article } from "../../../../../../types/knowledge-base/article";
import { DocumentContentsV2 } from "@hiyllo/editor/main/v2/types";
import { UseMoopsyMutationRetVal } from "@moopsyjs/react/main";
import * as UpdateArticleBP from "../../../../../../blueprints/knowledge-base/article/update-article";
import * as CreateFileUploadBP from "../../../../../../blueprints/fs/create-file-upload";
import { client } from "../../../../../../singletons/moopsy-client";
import { uploadFileToS3 } from "../../../../../../features/fs";
import { debounce } from "lodash";
import { useTheme } from "@hiyllo/ux/theme";
import { EditorV2 } from "@hiyllo/editor/main";

interface EditorComponentProps {
  article: Article;
  onSave: () => void;
  onCancel: () => void;
  updateArticleMutation: UseMoopsyMutationRetVal<UpdateArticleBP.Plug>;
  content: DocumentContentsV2 | null;
  onContentChange: (content: DocumentContentsV2) => void;
  isSaving: boolean;
  isLoading: boolean;
}

export const EditorComponent: React.FC<EditorComponentProps> = ({
  article,
  content,
  onContentChange,
  isSaving,
  onSave,
  isLoading,
}) => {
  const theme = useTheme();
  const createFileUploadMutation =
    client.useMutation<CreateFileUploadBP.Plug>(CreateFileUploadBP);

  const editorKey = `editor-${article.uuid}`;
  const [initialContent, setInitialContent] =
    React.useState<DocumentContentsV2 | null>(null);
  const [hasDraft, setHasDraft] = React.useState(false);
  const previousContentRef = React.useRef<DocumentContentsV2 | null>(null);
  const previousTextContentRef = React.useRef<string>("");

  const extractTextContent = (content: DocumentContentsV2 | null): string => {
    if (!content || !content.descendants) return "";

    return content.descendants
      .map((block) => {
        if (block.type === "paragraph" && Array.isArray(block.children)) {
          return block.children.map((child) => child.text || "").join("");
        }
        if (block.type === "hiylloImage") {
          return "";
        }
        return "";
      })
      .join("\n");
  };

  React.useEffect(() => {
    const draftContent = localStorage.getItem(`draft-${article.uuid}`);
    setHasDraft(!!draftContent);
    setInitialContent(content);
    previousContentRef.current = content;
    previousTextContentRef.current = extractTextContent(content);
  }, [content, article.uuid]);

  const handleValueChanged = debounce(
    (currentContent: DocumentContentsV2, _delta: BaseOperation[]) => {
      const currentTextContent = extractTextContent(currentContent);

      if (currentTextContent === "I'm a new document...") {
        return;
      }

      previousTextContentRef.current = currentTextContent;
      previousContentRef.current = currentContent;
      onContentChange(currentContent);

      localStorage.setItem(
        `draft-${article.uuid}`,
        JSON.stringify(currentContent)
      );
      setHasDraft(true);
    },
    300
  );

  const handleSaveDraft = () => {
    const draftContent = localStorage.getItem(`draft-${article.uuid}`);
    if (draftContent) {
      onSave();
      setHasDraft(false);
    }
  };

  if (isLoading) {
    return <div style={{ color: "white" }}>Loading...</div>;
  }

  return (
    <div
      style={{
        height: "95vh",
        position: "relative",
        color: theme.foreground,
      }}
    >
      <EditorV2
        key={editorKey}
        initialContents={content}
        onValueChanged={handleValueChanged}
        isSaving={isSaving}
        name={article.title || ""}
        noPadding={true}
        onImageUploaded={async (image) => {
          const uploadRes = await createFileUploadMutation.call({
            name: "image",
            mimeType: image.type,
            extension: image.type.split("/")[1],
          });

          await uploadFileToS3(
            new File([image], "image"),
            uploadRes.postOpts,
            () => {}
          );

          return { fsId: uploadRes.fsId, src: uploadRes.src };
        }}
      />

      {hasDraft && (
        <button
          style={{
            position: "absolute",
            bottom: "20px",
            right: "20px",
            padding: "10px 20px",
            borderRadius: "8px",
            backgroundColor: theme.background3,
            color: theme.foreground,
            cursor: "pointer",
            border: "none",
          }}
          onClick={handleSaveDraft}
        >
          Save Draft
        </button>
      )}
    </div>
  );
};

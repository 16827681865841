/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { DocumentContentsV2 } from "../../../types/knowledge-base/article";

export type ParamsType = {
  uuid: string;
  title?: string;
  content?: DocumentContentsV2;
  parentSectionUUID?: string;
  published?: boolean;
};
export type ResponseType = {};
export const Endpoint = "article/update-article";
export const Method = "POST";
export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const RateLimitingConfig = {
  calls: 2,
  per: 2500,
};
export const paramsSchema = {"type":"object","properties":{"uuid":{"type":"string"},"title":{"type":"string"},"content":{"$ref":"#/definitions/DocumentContentsV2"},"parentSectionUUID":{"type":"string"},"published":{"type":"boolean"}},"required":["uuid"],"definitions":{"DocumentContentsV2":{"type":"object","properties":{"v2":{"type":"boolean","enum":[true]},"descendants":{"type":"array","items":{"anyOf":[{"$ref":"#/definitions/ParagraphDescendantType"},{"$ref":"#/definitions/HiylloImageDescendantType"},{"$ref":"#/definitions/UnknownDescendantType"}]}}},"required":["descendants","v2"]},"ParagraphDescendantType":{"type":"object","properties":{"type":{"type":"string","enum":["paragraph"]},"children":{"type":"array","items":{"type":"object","properties":{"text":{"type":"string"}},"required":["text"]}}},"required":["children","type"]},"HiylloImageDescendantType":{"type":"object","properties":{"type":{"type":"string","enum":["hiylloImage"]},"src":{"type":"string"},"fsId":{"type":"string"}},"required":["fsId","src","type"]},"UnknownDescendantType":{"type":"object","additionalProperties":{},"properties":{"type":{"type":"string"},"children":{"anyOf":[{"type":"array","items":{"$ref":"#/definitions/UnknownDescendantType"}},{"type":"array","items":{"type":"object","properties":{"text":{"type":"string"}},"required":["text"]}}]}},"required":["type"]}},"$schema":"http://json-schema.org/draft-07/schema#"}
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { styled } from "@hiyllo/ux/styled";

interface AttachmentPreviewListProps {
  attachments: File[];
  setAttachments: React.Dispatch<React.SetStateAction<File[]>>;
}

const PreviewContainer = styled("div", {
  display: "flex",
  gap: "8px",
  flexWrap: "wrap",
  margin: "8px 0px",
});

const FileRow = styled("div", (props) => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  backgroundColor: props.$theme.background3,
  padding: "4px 8px",
  borderRadius: "8px",
  position: "relative",
}));

const FileImage = styled("img", {
  width: "30px",
  height: "30px",
  objectFit: "cover",
  borderRadius: "4px",
});

const RemoveButton = styled("button", (props) => ({
  position: "absolute",
  top: "-4px",
  right: "-4px",
  backgroundColor: props.$theme.foreground,
  color: "red",
  border: "none",
  borderRadius: "50%",
  width: "16px",
  height: "16px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  fontSize: "10px",
  fontWeight: "bold",
  transition: "background 0.2s ease",
}));

export const AttachmentPreviewList: React.FC<AttachmentPreviewListProps> = ({
  attachments,
  setAttachments,
}) => {
  const handleRemove = (index: number) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <PreviewContainer>
      {attachments.map((file, idx) => {
        const isImage = file.type.startsWith("image/");
        return (
          <FileRow key={idx}>
            {isImage ? (
              <FileImage src={URL.createObjectURL(file)} alt={file.name} />
            ) : (
              <FontAwesomeIcon icon={faFile} size="lg" />
            )}
            <span>{file.name}</span>
            <RemoveButton onClick={() => handleRemove(idx)}>
              <FontAwesomeIcon icon={faTimes} />
            </RemoveButton>
          </FileRow>
        );
      })}
    </PreviewContainer>
  );
};

// ArticleCard.tsx
import React from "react";
import { useTheme } from "@hiyllo/ux/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faFilePen } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Article } from "../../../../../../types/knowledge-base/article";
import { Button, ButtonVariant } from "@hiyllo/ux/button";

export const ArticleCard = ({
  article,
  onClick,
  endUser,
}: {
  article: Article;
  onClick: () => void;
  endUser?: boolean;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const getFirstParagraph = (content: any): string => {
    if (!content || !content.descendants) return "No content available";

    // Find the first 'paragraph' type node
    const firstParagraph = content.descendants.find(
      (node: any) => node.type === "paragraph"
    );

    if (!firstParagraph || !firstParagraph.children)
      return "No content available";

    // Concatenate all 'text' values from the children
    return (
      firstParagraph.children
        .map((child: any) => child.text)
        .join(" ")
        .slice(0, 300) + "..."
    );
  };

  return (
    <div
      style={{
        backgroundColor: endUser
          ? theme.colorScheme === "light"
            ? "rgba(255, 255, 255, 0.5)"
            : "rgba(0, 0, 0, 0.4)"
          : theme.background3,
        borderRadius: "10px",
        padding: "7.5px 12.5px",
        transition: "all 0.3s ease",
        minHeight: 30,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: `1px solid ${theme.midground1}`,
        marginBottom: endUser ? "0px" : "16px",
        width: endUser ? "100%" : "auto",
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        {endUser ? null : (
          <FontAwesomeIcon
            icon={faCircle}
            size="xs"
            color={article.published ? "#5B9279" : "#FF6978"}
            style={{ marginRight: 10 }}
          />
        )}
        <div style={{ width: "95%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 5,
            }}
          ></div>
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              color: theme.foreground,
              paddingBottom: 5,
            }}
          >
            {article.title}
          </div>
          <div
            style={{
              fontSize: "15px",
              color: theme.foreground,
              opacity: 0.6,
            }}
          >
            {getFirstParagraph(article.content)}
          </div>
        </div>
      </div>
      {endUser ? null : (
        <Button
          label={<FontAwesomeIcon icon={faFilePen} />}
          style={{
            background: theme.background1,
            color: theme.foreground,
            padding: "0px 6px 0px 10px",
          }}
          onClick={() =>
            navigate(`/admin/dashboard/knowledge-base/article/${article.uuid}`)
          }
          variant={ButtonVariant.narrow}
        />
      )}
    </div>
  );
};

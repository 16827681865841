/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export enum ConversationStatusEnum {
  open = "open",
  resolved = "resolved",
  inactive = "inactive",
}

export interface Conversation {
  uuid: string;
  mailMessageIds: string[];
  status: ConversationStatusEnum;
  external: {
    email: string;
    name: string | null;
  };
  subject: string;
  originator: "internal" | "external";
  lastSender: "internal" | "external";
  createdAt: Date;
  lastMessageAt: Date;
  priority: string;
  type: string;
  pinnedMessageIds: string[];
  assignedAgentUUID: string | null;
}

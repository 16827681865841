import { Button, ButtonVariant } from "@hiyllo/ux/button";
import { useTheme } from "@hiyllo/ux/theme";
import React from "react";

interface PaginationControlsProps {
  totalPages: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  pageNumber: number;
}

export const PaginationControls: React.FC<PaginationControlsProps> = React.memo(
  ({ totalPages, setPageNumber, pageNumber }) => {
    const theme = useTheme();

    return (
      <div
        style={{
          padding: "10px 0",
          textAlign: "center",
          color: theme.foreground,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => setPageNumber((prev) => Math.max(prev - 1, 1))}
          label="Previous"
          style={{ width: "50px" }}
          isSecondary={true}
          variant={ButtonVariant.narrow}
        />
        <span style={{ margin: "0 10px" }}>
          Page {pageNumber} of {totalPages}
        </span>
        <Button
          onClick={() =>
            setPageNumber((prev) => Math.min(prev + 1, totalPages))
          }
          style={{ width: "50px" }}
          label="Next"
          isSecondary={true}
          variant={ButtonVariant.narrow}
        />
      </div>
    );
  }
);

import React from "react";
import { useTenant } from "../../../../../providers/tenant-provider";
import { Input } from "@hiyllo/ux/input";
import { styled } from "@hiyllo/ux/styled";
import { Button } from "@hiyllo/ux/button";
import { Select } from "@hiyllo/ux/select";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import * as GetCustomTypesBP from "../../../../../blueprints/admin/conversations/get-conversation-types";
import * as CreateConvoBP from "../../../../../blueprints/admin/conversations/end-user-create-convo";
import { client } from "../../../../../singletons/moopsy-client";
import { Label } from "@hiyllo/ux/typography";
import { useMediaQuery } from "react-responsive";
import { useAlert } from "../../../../../providers/alert-provider";

const MainContainer = styled("div", (props) => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  marginTop: 20,
  color: props.$theme.foreground,
  height: "100%",
}));

const MainInnerContainer = styled("div", (props) => ({
  display: "flex",
  gap: 10,
}));

export const ContactSupportView = React.memo(() => {
  const tenant = useTenant();
  const { pushAlert } = useAlert();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [externalEmail, setExternalEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [name, setName] = React.useState("");
  const [selectedType, setSelectedType] = React.useState<string[]>([]);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const getCustomTypesQuery = client.useQuery<GetCustomTypesBP.Plug>(
    GetCustomTypesBP,
    {}
  );

  const createConvoMutation =
    client.useMutation<CreateConvoBP.Plug>(CreateConvoBP);

  const handleTypeSelect = React.useCallback(
    (value: string | number | boolean | null) => {
      const selectedValues = (value as string).split(",");
      setSelectedType(selectedValues);
    },
    []
  );

  const types = React.useMemo(
    () => getCustomTypesQuery.data?.types || [],
    [getCustomTypesQuery.data?.types]
  );

  const handleSend = React.useCallback(() => {
    if (
      !externalEmail ||
      !emailRegex.test(externalEmail) ||
      !message ||
      !name ||
      !subject ||
      selectedType.length === 0
    ) {
      pushAlert(
        !externalEmail
          ? "Email is required"
          : !emailRegex.test(externalEmail)
          ? "Please enter a valid email address"
          : "Please fill in all fields",
        "error"
      );
      return;
    }

    createConvoMutation
      .call({
        externalEmail,
        message,
        name,
        subject,
        type: selectedType[0],
      })
      .finally(() => {
        setExternalEmail("");
        setMessage("");
        setName("");
        setSubject("");
        setSelectedType([]);

        pushAlert(
          createConvoMutation.error
            ? createConvoMutation.error.message
            : "Message sent successfully",
          createConvoMutation.error ? "error" : "success"
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    externalEmail,
    emailRegex,
    message,
    name,
    subject,
    selectedType.length,
    pushAlert,
  ]);

  return (
    <div>
      <h1 style={{ marginBottom: 5 }}>
        Get support with {tenant.appearance.name}
      </h1>
      <p>
        If you have any questions or need help, please contact our support team
      </p>
      <MainContainer>
        <MainInnerContainer>
          <div
            style={{
              display: "flex",
              gap: 10,
              width: "100%",
            }}
          >
            <Input
              value={name}
              label="Your name"
              placeholder="Enter your name"
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              value={externalEmail}
              label="Your email"
              placeholder="Enter your email"
              onChange={(e) => setExternalEmail(e.target.value)}
            />
            {types.length > 0 ? (
              <div style={{ width: "100%" }}>
                <Label>Type</Label>
                <Select
                  value={selectedType.join(",")}
                  onChangeValue={handleTypeSelect}
                  options={types.map((type) => ({
                    label: type.label,
                    value: type.key,
                  }))}
                  fullWidth
                  placeholder="Select a type"
                  containerStyle={{
                    width: isMobile ? "auto" : "min(50%, 200px)",
                  }}
                />
              </div>
            ) : (
              <>
                <LoadingSpinner />
              </>
            )}
          </div>
        </MainInnerContainer>
        <Input
          value={subject}
          label="Subject"
          placeholder="Enter the subject"
          onChange={(e) => setSubject(e.target.value)}
          fullWidth={true}
        />
        <Input
          value={message}
          label="Message"
          placeholder="Type your message here"
          multiline={true}
          fullWidth={true}
          inputStyle={{
            minHeight: "45vh",
          }}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button
          label="Send"
          onClick={handleSend}
          style={{ marginTop: 5, width: 100, alignSelf: "flex-end" }}
        />
      </MainContainer>
    </div>
  );
});

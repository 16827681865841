import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { EditorComponent } from "../components/article/article-editor";
import { styled } from "@hiyllo/ux/styled";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { useAlert } from "../../../../../providers/alert-provider";
import { useShowConfirm } from "@hiyllo/ux/dialogs";
import { client } from "../../../../../singletons/moopsy-client";
import * as UpdateArticleBP from "../../../../../blueprints/knowledge-base/article/update-article";
import * as RetrieveArticleBP from "../../../../../blueprints/knowledge-base/article/retrieve-article";
import { Article } from "../../../../../types/knowledge-base/article";
import { DocumentContentsV2 } from "@hiyllo/editor/v2/types";

const Container = styled("div", {
  margin: "20px 20px",
  overflowY: "hidden",
});

export const ArticleEditView: React.FC = React.memo(() => {
  const { uuid } = useParams<{ uuid: string }>();
  const { pushAlert } = useAlert();
  const navigate = useNavigate();
  const showConfirm = useShowConfirm();

  const [editedArticleContent, setEditedArticleContent] =
    React.useState<DocumentContentsV2 | null>(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isLoadingEditingArticle, setIsLoadingEditingArticle] =
    React.useState(true);
  const [editingArticle, setEditingArticle] = React.useState<Article | null>(
    null
  );

  const articleQuery = client.useQuery<RetrieveArticleBP.Plug>(
    RetrieveArticleBP,
    { uuid: uuid || "" }
  );

  const updateArticleMutation = client.useMutation<UpdateArticleBP.Plug>(
    UpdateArticleBP,
    {}
  );

  React.useEffect(() => {
    if (articleQuery.data && !articleQuery.isLoading && !articleQuery.isError) {
      const article = articleQuery.data.article;
      setEditingArticle(article);

      const draftContent = localStorage.getItem(`draft-${article.uuid}`);
      const parsedDraftContent = draftContent ? JSON.parse(draftContent) : null;

      const isDraftIdentical =
        parsedDraftContent &&
        JSON.stringify(parsedDraftContent) === JSON.stringify(article.content);

      if (isDraftIdentical) {
        // Draft is identical to the article content
        localStorage.removeItem(`draft-${article.uuid}`);
        setEditedArticleContent(article.content as DocumentContentsV2);
        setIsLoadingEditingArticle(false);
      } else if (
        parsedDraftContent &&
        parsedDraftContent.descendants?.length > 0 &&
        JSON.stringify(parsedDraftContent) !== JSON.stringify(article.content)
      ) {
        // If the draft is different, prompt user to load it
        showConfirm({
          title: "Load Draft",
          message:
            "A draft version of this article was found. Do you want to load it?",
          confirmLabel: "Yes",
        }).then((confirmed) => {
          if (confirmed) {
            setEditedArticleContent(parsedDraftContent);
          } else {
            setEditedArticleContent(article.content as DocumentContentsV2);
          }
          setIsLoadingEditingArticle(false);
        });
      } else {
        // No draft or no differences
        setEditedArticleContent(article.content as DocumentContentsV2);
        setIsLoadingEditingArticle(false);
      }
    }
  }, [
    articleQuery.data,
    articleQuery.isLoading,
    articleQuery.isError,
    showConfirm,
  ]);

  const handleSaveContent = React.useCallback(async () => {
    if (!editingArticle) return;

    const draftContent = localStorage.getItem(`draft-${editingArticle.uuid}`);

    if (draftContent) {
      setIsSaving(true);
      try {
        await updateArticleMutation.call({
          uuid: editingArticle.uuid,
          content: JSON.parse(draftContent),
        });

        localStorage.removeItem(`draft-${editingArticle.uuid}`);
        pushAlert("Article saved successfully.", "success");
      } catch (error) {
        pushAlert((error as Error).message, "error");
      } finally {
        setIsSaving(false);
      }
    } else {
      alert("No changes to save");
    }
  }, [editingArticle, pushAlert, updateArticleMutation]);

  const handleCancelEdit = React.useCallback((): void => {
    navigate("/admin/dashboard/knowledge-base/articles");
  }, [navigate]);

  if (articleQuery.isLoading || !editingArticle || !editedArticleContent) {
    return (
      <Container>
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container>
      <EditorComponent
        article={editingArticle}
        content={editedArticleContent}
        onContentChange={setEditedArticleContent}
        updateArticleMutation={updateArticleMutation}
        onSave={handleSaveContent}
        onCancel={handleCancelEdit}
        isSaving={isSaving}
        isLoading={isLoadingEditingArticle}
      />
    </Container>
  );
});

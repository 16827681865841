import {
  faItalic,
  faBold,
  faSmile,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { hexToRGBA } from "../../../utils";
import { useTheme } from "@hiyllo/ux/theme";
import { faFileArrowUp, faUnderline } from "@fortawesome/pro-solid-svg-icons";
import { styled } from "@hiyllo/ux/styled";
import { FastReply } from "./fast-reply-modal";

interface InputOptionsProps {
  replyInputControl: any;
  setAttachments: React.Dispatch<React.SetStateAction<File[]>>;
}

const InputButton = styled("div", (props) => ({
  backgroundColor: props.$theme.midground1,
  color: props.$theme.foreground,
  border: "none",
  borderRadius: "5px",
  padding: "8px 10px",
  cursor: "pointer",
  transition: "background 0.2s",
  ":hover": {
    backgroundColor: hexToRGBA(props.$theme.midground2 || "", 0.6),
  },
}));

export const InputOptions: React.FC<InputOptionsProps> = React.memo(
  ({ replyInputControl, setAttachments }) => {
    const theme = useTheme();
    const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);

    const toggleDropdown = () => {
      setIsDropdownVisible((prev) => !prev);
    };

    const handleFastReplyClick = (reply: string) => {
      // Append the fast reply to the current input value
      replyInputControl.update(`${replyInputControl.value} ${reply}`.trim());
      setIsDropdownVisible(false);
    };

    const handleCloseDropdown = () => {
      setIsDropdownVisible(false);
    };

    const handleFileChange = React.useCallback(
      async (evt: React.ChangeEvent<HTMLInputElement>) => {
        const files = evt.target.files;

        console.log("<<<<<" + files);

        setAttachments((prev) => [...prev, ...Array.from(files ?? [])]);
      },
      []
    );

    const toggleIcon = isDropdownVisible ? faAngleUp : faAngleDown;

    return (
      <div
        style={{
          paddingLeft: "5px",
          display: "flex",
          flexDirection: "column",
          paddingBottom: "5px",
          borderBottom: `1px solid ${theme.midground2}`,
          position: "relative",
        }}
      >
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <label>
            <InputButton>
              <FontAwesomeIcon icon={faFileArrowUp} />
            </InputButton>
            <input
              type="file"
              style={{ display: "none" }} // Hide the actual input
              onChange={handleFileChange}
            />
          </label>
          <InputButton>
            <FontAwesomeIcon icon={faBold} />
          </InputButton>
          <InputButton>
            <FontAwesomeIcon icon={faItalic} />
          </InputButton>
          <InputButton>
            <FontAwesomeIcon icon={faUnderline} />
          </InputButton>
          <InputButton>
            <FontAwesomeIcon icon={faSmile} />
          </InputButton>
          <span
            style={{
              padding: "8px",
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: hexToRGBA(theme.foreground ?? "", 0.3),
              borderRadius: "10px",
              cursor: "pointer",
              display: "inline-block",
              fontSize: "12px",
            }}
            onClick={toggleDropdown}
          >
            Fast Reply <FontAwesomeIcon icon={toggleIcon} />
          </span>
        </div>

        {isDropdownVisible && (
          <FastReply
            handleFastReplyClick={handleFastReplyClick}
            onClose={handleCloseDropdown}
          />
        )}
      </div>
    );
  }
);

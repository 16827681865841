import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { Input } from "@hiyllo/ux/input";
import { Select } from "@hiyllo/ux/select";
import { Button } from "@hiyllo/ux/button";
import { Typography } from "@hiyllo/ux/typography";
import * as GetCustomTypesBP from "../../../blueprints/admin/conversations/get-conversation-types";
import * as GetCustomPrioritiesBP from "../../../blueprints/admin/conversations/get-conversation-priorities";
import * as CreateConversationBP from "../../../blueprints/admin/conversations/create-conversation";
import { client } from "../../../singletons/moopsy-client";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";

const Container = styled("div", (props) => ({
  backgroundColor: props.$theme.background1,
  color: props.$theme.foreground,
  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
}));

const Field = styled("div", {
  marginBottom: "15px",
});

const Label = styled("div", (props) => ({
  display: "block",
  marginBottom: "5px",
  fontSize: "14px",
  color: props.$theme.foreground,
}));

export const CreateConversation = React.memo(() => {
  const [externalEmail, setExternalEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [selectedPriority, setSelectedPriority] = React.useState<string[]>([]);
  const [selectedType, setSelectedType] = React.useState<string[]>([]);

  const getCustomTypesQuery = client.useQuery<GetCustomTypesBP.Plug>(
    GetCustomTypesBP,
    {}
  );

  const getCustomPrioritiesQuery = client.useQuery<GetCustomPrioritiesBP.Plug>(
    GetCustomPrioritiesBP,
    {}
  );

  const createConversationMutation =
    client.useMutation<CreateConversationBP.Plug>(CreateConversationBP);

  const types = React.useMemo(
    () => getCustomTypesQuery.data?.types || [],
    [getCustomTypesQuery.data?.types]
  );

  const priorities = React.useMemo(
    () => getCustomPrioritiesQuery.data?.priorities || [],
    [getCustomPrioritiesQuery.data?.priorities]
  );

  const handleTypeSelect = React.useCallback(
    (value: string | number | boolean | null) => {
      const selectedValues = (value as string).split(",");
      setSelectedType(selectedValues);
    },
    []
  );

  const handlePrioritySelect = React.useCallback(
    (value: string | number | boolean | null) => {
      const selectedValues = (value as string).split(",");
      setSelectedPriority(selectedValues);
    },
    []
  );

  const handleCreateConversation = React.useCallback(() => {
    createConversationMutation.call({
      externalEmail,
      subject,
      priority: selectedPriority.join(","),
      type: selectedType.join(","),
    });
    window.location.reload();
  }, [externalEmail, subject, selectedPriority, selectedType]);

  return (
    <Container>
      <Typography.Header>Create a New Conversation</Typography.Header>
      <Typography.Paragraph>
        Once created, you can send a message to the customer (via email only for
        now), and they can reply to it.
      </Typography.Paragraph>

      <div style={{ paddingTop: 20 }}>
        <Field>
          <Label>Email</Label>
          <Input
            placeholder="Enter customer's email"
            value={externalEmail}
            onChange={(e) => setExternalEmail(e.target.value)}
            fullWidth
          />
        </Field>
        <Field>
          <Label>Subject</Label>
          <Input
            placeholder="Enter subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            fullWidth
          />
        </Field>
        <Field>
          <Label>Priority</Label>
          {priorities.length > 0 ? (
            <Select
              value={selectedPriority.join(",")}
              onChangeValue={handlePrioritySelect}
              options={priorities.map((priority) => ({
                label: priority.label,
                value: priority.key,
              }))}
              fullWidth
              placeholder="Select a priority"
              containerStyle={{
                width: "100%",
                borderRadius: "4px",
                fontSize: "14px",
              }}
            />
          ) : (
            <>
              <LoadingSpinner />
            </>
          )}
        </Field>
        <Field>
          <Label>Type</Label>
          {types.length > 0 ? (
            <Select
              value={selectedType.join(",")}
              onChangeValue={handleTypeSelect}
              options={types.map((type) => ({
                label: type.label,
                value: type.key,
              }))}
              fullWidth
              placeholder="Select a type"
              containerStyle={{
                width: "100%",
                borderRadius: "4px",
                fontSize: "14px",
              }}
            />
          ) : (
            <>
              <LoadingSpinner />
            </>
          )}
        </Field>
        <Button
          label="Create Conversation"
          onClick={handleCreateConversation}
        />
      </div>
    </Container>
  );
});

import React from "react";
import { Input } from "@hiyllo/ux/input";
import { Button } from "@hiyllo/ux/button";
import { styled } from "@hiyllo/ux/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import { InputOptions } from "./input-options";
import { AttachmentPreviewList } from "./attachment-block";

const FooterContainer = styled("div", ({ $theme }) => ({
  padding: "5px",
  backgroundColor: $theme.midground1,
  display: "flex",
  flexDirection: "column",

  position: "absolute",
  bottom: 0,
  width: "calc(100% - 372px)",
}));

const FooterInput = styled("div", ({ $theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  width: "100%",
  padding: "5px 5px 0px 0px",
}));

export const Footer: React.FC<{
  sendReply: () => void;
  replyInputControl: any;
  attachments: File[];
  setAttachments: React.Dispatch<React.SetStateAction<File[]>>;
  isMessageSending: boolean;
}> = React.memo(
  ({
    sendReply,
    replyInputControl,
    attachments,
    setAttachments,
    isMessageSending,
  }) => (
    <FooterContainer>
      {attachments.length > 0 && (
        <AttachmentPreviewList
          attachments={attachments}
          setAttachments={setAttachments}
        />
      )}
      <InputOptions
        replyInputControl={replyInputControl}
        setAttachments={setAttachments}
      />
      <FooterInput>
        <Input
          multiline
          placeholder="Type something..."
          onSubmit={sendReply}
          containerStyle={{
            backgroundColor: "transparent",
            fontSize: "15px",
            boxShadow: "none",
          }}
          fullWidth={true}
          {...replyInputControl.inputProps}
        />
        <Button
          onClick={sendReply}
          isLoading={isMessageSending}
          label={<FontAwesomeIcon icon={faPaperPlane} />}
          style={{
            width: 35,
            height: 35,
            fontSize: 15,
          }}
        />
      </FooterInput>
    </FooterContainer>
  )
);

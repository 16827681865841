import {
  faCircle,
  faEdit,
  faFolder,
  faGripVertical,
  faMemoPad,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@hiyllo/ux/styled";
import { ThemeType, useTheme } from "@hiyllo/ux/theme";
import React from "react";
import { Section } from "../../../../../../types/knowledge-base/section";
import { IconDisplay } from "@hiyllo/ux/icon-display";

const Container = styled("div", (props) => ({
  display: "flex",
  backgroundColor: props.$theme.background3,
  borderRadius: "8px",
  padding: "10px",
}));

const IconWrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "15px",
  marginLeft: "5px",
});

const ContentWrapper = styled("div", {
  width: "100%",
});

const Header = styled("div", {
  display: "flex",
  gap: 10,
  alignItems: "center",
  justifyContent: "space-between",
});

const TitleWrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
});

const SectionDetails = styled("div", {
  display: "flex",
  alignItems: "center",
  gap: 10,
});

const Description = styled("div", (props) => ({
  fontSize: "14px",
  color: props.$theme.foreground,
  opacity: 0.5,
  marginTop: 5,
}));

const ActionsWrapper = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "10px",
});

const ActionButton = styled(
  "div",
  (props: { $background?: string; $theme: ThemeType }) => ({
    background: props.$background || props.$theme.background2,
    color: props.$theme.foreground,
    border: "none",
    outline: "none",
    borderRadius: "4px",
    padding: "2px 6px",
    marginBottom: 0,
    cursor: "pointer",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  })
);

interface SectionAdminCardProps {
  section: Section;
  setEditingSection: (section: Section) => void;
  handlePublish: (section: Section) => void;
  handleDeleteSection: (uuid: string) => void;
  getChildSectionCount: (uuid: string) => number;
}

export const SectionAdminCard: React.FC<SectionAdminCardProps> = React.memo(
  (props) => {
    const theme = useTheme();
    return (
      <Container>
        <IconWrapper>
          <FontAwesomeIcon icon={faGripVertical} style={{ fontSize: 15 }} />
        </IconWrapper>
        <ContentWrapper>
          <Header>
            <TitleWrapper>
              <FontAwesomeIcon
                icon={faCircle}
                size={"2xs"}
                style={{
                  color: props.section.published ? "#5B9279" : "#FF6978",
                  marginRight: "5px",
                }}
              />
              <h2
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: theme.foreground,
                  margin: 0,
                }}
              >
                {props.section.title}
              </h2>
              {props.section.icon && (
                <IconDisplay
                  iconName={props.section.icon}
                  size="lg"
                  color={theme.foreground}
                />
              )}
            </TitleWrapper>
            <SectionDetails>
              {props.getChildSectionCount(props.section.uuid) > 0 && (
                <div>
                  <FontAwesomeIcon
                    icon={faFolder}
                    style={{
                      marginRight: "5px",
                      color: theme.foreground,
                    }}
                  />
                  <span>{props.getChildSectionCount(props.section.uuid)}</span>
                </div>
              )}
              {Array.isArray(props.section.articles) &&
                props.section.articles.length > 0 && (
                  <div>
                    <FontAwesomeIcon
                      icon={faMemoPad}
                      style={{
                        marginRight: "5px",
                        color: theme.foreground,
                      }}
                    />
                    <span style={{ color: theme.foreground }}>
                      {props.section.articles.length}
                    </span>
                  </div>
                )}
            </SectionDetails>
          </Header>
          <Description>{props.section.description}</Description>
          <ActionsWrapper>
            <div style={{ display: "flex", gap: 5 }}>
              <ActionButton
                $theme={theme}
                onClick={(e: { stopPropagation: () => void }) => {
                  e.stopPropagation();
                  props.setEditingSection(props.section);
                }}
                style={{ width: 30, height: 25 }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </ActionButton>
              <ActionButton
                $theme={theme}
                onClick={(e: { stopPropagation: () => void }) => {
                  e.stopPropagation();
                  props.handlePublish(props.section);
                }}
              >
                {props.section.published ? "Unpublish" : "Publish"}
              </ActionButton>
            </div>
            <ActionButton
              $theme={theme}
              $background="rgba(230, 57, 70, 1)"
              onClick={(e: { stopPropagation: () => void }) => {
                e.stopPropagation();
                props.handleDeleteSection(props.section.uuid);
              }}
            >
              Delete
            </ActionButton>
          </ActionsWrapper>
        </ContentWrapper>
      </Container>
    );
  }
);

import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { hexToRGBA } from "../../../utils";
import { useShowConfirm, useShowDialog } from "@hiyllo/ux/dialogs";
import { useAlert } from "../../../providers/alert-provider";
import * as GetBrandFastRepliesBP from "../../../blueprints/util/get-brand-fast-replies";
import * as GetUserFastRepliesBP from "../../../blueprints/user/get-user-fast-replies";
import * as AddUserFastReplyBP from "../../../blueprints/user/add-user-fast-reply";
import * as ModifyUserFastReplyBP from "../../../blueprints/user/modify-user-fast-reply";
import { client } from "../../../singletons/moopsy-client";
import { UserFastReplyType } from "../../../types/users/user-fast-reply";
import NoContent from "../../../components/no-content";
import { styled } from "@hiyllo/ux/styled";
import { BrandFastReplyType } from "../../../types/util/brand-fast-reply";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@hiyllo/ux/theme";

interface FastReplyProps {
  handleFastReplyClick: (reply: string) => void;
  onClose: () => void;
}

const Container = styled("div", (props) => ({
  position: "fixed", // Change from absolute to fixed
  bottom: 110,
  left: 370,
  width: "300px",
  background: props.$theme.midground1,
  borderRadius: "12px",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
  border: `1px solid ${hexToRGBA(props.$theme.foreground ?? "", 0.1)}`,
  padding: "10px",
  zIndex: 40,
}));

const TabButton = styled<"div", { $isActive: boolean }>("button", (props) => ({
  flex: 1,
  padding: "8px",
  background: props.$isActive ? props.$theme.midground2 : "transparent",
  border: "none",
  borderRadius: "8px",
  cursor: "pointer",
  color: props.$theme.foreground,
  transition: "background 0.2s",
}));

const ReplyItem = styled("div", (props) => ({
  padding: "10px",
  cursor: "pointer",
  borderRadius: "8px",
  transition: "background 0.2s",
  color: props.$theme.foreground,
  marginBottom: "8px",
  background: hexToRGBA(props.$theme.midground2 ?? "", 0.5),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const AddReplyButton = styled("div", (props) => ({
  marginTop: "10px",
  padding: "10px",
  cursor: "pointer",
  textAlign: "center",
  color: props.$theme.foreground,
  borderRadius: "8px",
  background: props.$theme.colorSubtleAccent,
  transition: "background 0.2s",
}));

export const FastReply: React.FC<FastReplyProps> = React.memo(
  ({ handleFastReplyClick, onClose }) => { // Added onClose 
    const theme = useTheme();
    const { pushAlert } = useAlert();
    const showDialog = useShowDialog();
    const showConfirm = useShowConfirm();
    const [currentTab, setCurrentTab] = React.useState<"brand" | "user">(
      "brand"
    );
    const [userReplies, setUserReplies] = React.useState<
      UserFastReplyType[] | null
    >(null);
    const [brandReplies, setBrandReplies] = React.useState<
      BrandFastReplyType[] | null
    >(null);
    const [isModalOpen, setIsModalOpen] = React.useState(true);

    const getBrandFastRepliesQuery =
      client.useQuery<GetBrandFastRepliesBP.Plug>(GetBrandFastRepliesBP, {});
    const getUserFastRepliesQuery = client.useQuery<GetUserFastRepliesBP.Plug>(
      GetUserFastRepliesBP,
      {}
    );
    const addUserFastRepliesMutation =
      client.useMutation<AddUserFastReplyBP.Plug>(AddUserFastReplyBP, {
        querySideEffects: [getUserFastRepliesQuery],
      });
    const modifyUserFastRepliesMutation =
      client.useMutation<ModifyUserFastReplyBP.Plug>(ModifyUserFastReplyBP, {
        querySideEffects: [getUserFastRepliesQuery],
      });

    React.useEffect(() => {
      if (getBrandFastRepliesQuery.data?.replies) {
        setBrandReplies(getBrandFastRepliesQuery.data.replies);
      }
    }, [getBrandFastRepliesQuery.data]);

    React.useEffect(() => {
      if (getUserFastRepliesQuery.data?.replies) {
        setUserReplies(getUserFastRepliesQuery.data.replies);
      }
    }, [getUserFastRepliesQuery.data]);

    const dropdownVariants = React.useMemo(
      () => ({
        hidden: { opacity: 0, y: 10 },
        visible: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 10 },
      }),
      []
    );

    const handleDeleteReply = React.useCallback(
      (reply: UserFastReplyType) => {
        showConfirm({
          title: "Delete fast reply",
          message: "Are you sure you want to delete this fast reply?",
        }).then((confirmed) => {
          if (confirmed) {
            modifyUserFastRepliesMutation.call({
              uuid: reply.uuid,
              delete: true,
            });
          }
        });
      },
      [showConfirm]
    );

    const handleAddReply = React.useCallback(() => {
      showDialog({
        title: "Add fast reply",
        message: "Enter the fast reply you'd like to add.",
        requireValue: true,
        onSubmit: async (input) => {
          if (!input) {
            pushAlert("Reply cannot be empty", "error");
            return;
          }
          addUserFastRepliesMutation.call({ text: input });
        },
      });
    }, [showDialog, addUserFastRepliesMutation, pushAlert]);

    const handleEditReply = React.useCallback(
      (uuid: string) => {
        showDialog({
          title: "Edit Reply",
          message: `Modify and then save your changes to the reply.`,
          requireValue: true,
          defaultValue:
            userReplies?.find((reply) => reply.uuid === uuid)?.text ?? "",
          onSubmit: async (newContent) => {
            if (!newContent) {
              pushAlert("Nothing changed.", "error");
              return;
            }
            try {
              await modifyUserFastRepliesMutation.call({
                uuid,
                newContent,
              });
            } catch (error) {
              pushAlert("An error occurred while editing the reply.", "error");
            }
          },
        });
      },
      [modifyUserFastRepliesMutation, pushAlert, showDialog, userReplies]
    );

   const handleBackdropClick = React.useCallback(async () => {
      onClose();
      // await new Promise(resolve => setTimeout(resolve, 300));
      // setIsModalOpen(false);
    }, []);

    const handleModalClick = React.useCallback((event: React.MouseEvent) => {
      event.stopPropagation();
    }, []);

    return isModalOpen ? (
      <AnimatePresence>
        <motion.div
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={dropdownVariants}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 40,
          }}
          onClick={handleBackdropClick} // Handle clicks on the backdrop
        >
          <Container onClick={handleModalClick} style={{ margin: "auto" }}>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <TabButton
                onClick={() => setCurrentTab("brand")}
                $isActive={currentTab === "brand"}
              >
                Preset Replies
              </TabButton>
              <TabButton
                onClick={() => setCurrentTab("user")}
                $isActive={currentTab === "user"}
              >
                Your Replies
              </TabButton>
            </div>

            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={dropdownVariants}
            >
              {currentTab === "brand" &&
                (brandReplies && brandReplies.length > 0 ? (
                  brandReplies.map((reply) => (
                    <ReplyItem
                      key={reply.uuid}
                      onClick={() => {
                        handleFastReplyClick(reply.text); // Should close after selecting reply
                        onClose();
                      }}
                      role="button"
                      tabIndex={0}
                      aria-label={`Use fast reply: ${reply.text}`}
                    >
                      {reply.text}
                    </ReplyItem>
                  ))
                ) : (
                  <NoContent plug="Your company hasn't set up any preset Fast Replies yet.." />
                ))}

              {currentTab === "user" &&
                (userReplies && userReplies.length > 0 ? (
                  userReplies.map((reply, index) => (
                    <ReplyItem
                      key={reply.uuid}
                      onClick={() => handleFastReplyClick(reply.text)}
                    >
                      <span>{reply.text}</span>
                      <div>
                        <button
                          onClick={(event) => {
                            event.stopPropagation();
                            handleEditReply(reply.uuid);
                          }}
                          style={{
                            background: "none",
                            border: "none",
                            color: theme.buttonForeground,
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                          aria-label={`Delete reply: ${reply}`}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                        <button
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDeleteReply(reply);
                          }}
                          style={{
                            background: "none",
                            border: "none",
                            color: "rgba(230, 57, 70, 1)",
                            cursor: "pointer",
                            fontWeight: "bold",
                          }}
                          aria-label={`Delete reply: ${reply}`}
                        >
                          <FontAwesomeIcon icon={faTrashCan} />
                        </button>
                      </div>
                    </ReplyItem>
                  ))
                ) : (
                  <NoContent plug="You haven't set up any preset Fast Replies yet.." />
                ))}

              {currentTab === "user" && (
                <AddReplyButton
                  onClick={handleAddReply}
                  role="button"
                  tabIndex={0}
                  aria-label="Add a new user reply"
                >
                  + Add New Reply
                </AddReplyButton>
              )}
            </motion.div>
          </Container>
        </motion.div>
      </AnimatePresence>
    ) : null;
  }
);

/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  uuid: string;
  newContent?: string;
  delete?: boolean;
};
export type ResponseType = {};
export const Endpoint = "modify-brand-fast-reply";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

const isPublic = false;

export const RateLimitingConfig = {
  calls: 3,
  per: 2000,
};
export const paramsSchema = {"type":"object","properties":{"uuid":{"type":"string"},"newContent":{"type":"string"},"delete":{"type":"boolean"}},"required":["uuid"],"$schema":"http://json-schema.org/draft-07/schema#"}
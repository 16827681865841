import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { MessageOptions } from "../components/message-options";
import { hexToRGBA } from "../../../utils";
import { Message } from "../../../types/conversations/message";
import * as GetSrcFromFsId from "../../../blueprints/util/get-src-from-fsids";
import { client } from "../../../singletons/moopsy-client";
import { useAlert } from "../../../providers/alert-provider";
import { FileGallery, GalleryItem } from "../../../file-gallery";
import { Conversation } from "../../../types/conversations/conversation";

const MessageBubbleContainer = styled("div", ({ $theme }) => ({
  display: "block",
  width: "100%",
  color: $theme.foreground,
  fontSize: "15px",
  lineHeight: 1.4,
  padding: "3px",
  position: "relative",
  ":hover": {
    backgroundColor: hexToRGBA($theme.midground1, 0.2),
  },
}));

export const MessageBubble: React.FC<{
  message: Message;
  isHovered: boolean;
  onHover: (id: string | null) => void;
  conversation: Conversation;
}> = React.memo(({ message, isHovered, onHover, conversation }) => {
  const { pushAlert } = useAlert();
  const [attachmentSrcs, setAttachmentSrcs] = React.useState<string[]>([]);
  const [fetchedUUIDs, setFetchedUUIDs] = React.useState<Set<string>>(
    new Set()
  );
  const getSrcFromFsIdMutation =
    client.useMutation<GetSrcFromFsId.Plug>(GetSrcFromFsId);

  // Use stable reference for message.attachmentUUIDs
  const attachmentUUIDs = React.useMemo(
    () => message.attachmentUUIDs || [],
    [message.attachmentUUIDs]
  );

  const fetchAttachmentSources = React.useCallback(async () => {
    // Filter out UUIDs that have already been fetched
    const uuidsToFetch = attachmentUUIDs.filter(
      (uuid) => !fetchedUUIDs.has(uuid)
    );

    if (uuidsToFetch.length === 0) return;

    try {
      const response = await getSrcFromFsIdMutation.call({
        fsIds: uuidsToFetch,
      });

      if (response && response.srcs) {
        // Update attachment sources
        setAttachmentSrcs((prev) => [...prev, ...response.srcs]);

        // Track fetched UUIDs to prevent re-fetching
        setFetchedUUIDs((prev) => {
          const newSet = new Set(prev);
          uuidsToFetch.forEach((uuid) => newSet.add(uuid));
          return newSet;
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        pushAlert(error.message, "error");
      } else {
        pushAlert("An unknown error occurred", "error");
      }
    }
  }, [attachmentUUIDs, fetchedUUIDs, getSrcFromFsIdMutation, pushAlert]);

  React.useEffect(() => {
    // Only fetch if there are attachments and they haven't been fetched
    if (attachmentUUIDs.length > 0) {
      fetchAttachmentSources();
    }
  }, []);

  const galleryItems = React.useMemo(() => {
    return attachmentSrcs.length > 0 ? [{ src: attachmentSrcs[0] }] : [];
  }, [attachmentSrcs]);

  return (
    <>
      {conversation.pinnedMessageIds.includes(message.uuid) ? (
        <div>Pinned</div>
      ) : null}
      <MessageBubbleContainer
        onMouseEnter={() => onHover(message.uuid)}
        onMouseLeave={() => onHover(null)}
      >
        {message.content}
        {galleryItems.length > 0 && (
          <FileGallery files={galleryItems as [GalleryItem]} />
        )}
        {isHovered && <MessageOptions message={message} />}
      </MessageBubbleContainer>
    </>
  );
});

/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { UserFastReplyType } from "../../types/users/user-fast-reply";

export type ParamsType = {};
export type ResponseType = {
  replies: UserFastReplyType[] | null;
};
export const Endpoint = "get-user-fast-replies";
export const Method = "GET";

export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}

const isPublic = false;

export const RateLimitingConfig = {
  calls: 3,
  per: 2000,
};
export const paramsSchema = {"type":"object","properties":{},"$schema":"http://json-schema.org/draft-07/schema#"}
/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export enum MessageOrigin {
  Email = "email",
  SelfService = "selfService",
}

export interface InternalSender {
  type: "internal";
  hiylloID: string;
}

export interface ExternalSender {
  type: "external";
  email: string;
}

export interface Message {
  uuid: string;
  conversationUUID: string;
  sender: InternalSender | ExternalSender;
  content: string;
  attachmentUUIDs?: string[];
  date: Date;
  mailMessageId?: string | null;
  origin: MessageOrigin;
}

import React from "react";
import { Article } from "../../../../../types/knowledge-base/article";
import { Section } from "../../../../../types/knowledge-base/section";
import { ArticleCard } from "../../admin/components/section/article-card";
import { SearchBar } from "../components/search-bar";
import { SectionCard } from "../components/section-card";
import * as SearchKbBP from "../../../../../blueprints/knowledge-base/util/search-kb";
import { client } from "../../../../../singletons/moopsy-client";

interface Suggestion {
  title: string;
  uuid: string;
  icon: string;
  type: string; // "section" or "article"
}

interface HomeContentProps {
  rootSections: Section[];
  articles: Article[];
  onSectionClick: (section: Section) => void;
  onArticleClick: (article: Article) => void;
  isMobile: boolean;
}

export const HomeContent: React.FC<HomeContentProps> = React.memo(
  ({ rootSections, articles, onSectionClick, onArticleClick, isMobile }) => {
    const searchKbMutation = client.useMutation<SearchKbBP.Plug>(SearchKbBP);

    const handleSearch = React.useCallback(
      async (searchTerm: string): Promise<Suggestion[]> => {
        const response = await searchKbMutation.call({ searchTerm });

        if (response && response.result) {
          return response.result.map((item: any) => ({
            uuid: item.uuid,
            title: item.title,
            icon: item.icon,
            type: item.type,
          }));
        }

        return [];
      },
      []
    );

    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            minWidth: "40%",
          }}
        >
          <h1 style={{ marginBottom: 20, zIndex: 10 }}>We're here to help</h1>
          <SearchBar
            onSearch={(searchTerm: string) => handleSearch(searchTerm)}
          />
        </div>
        <div style={{ height: 40 }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
            zIndex: 1,
          }}
        >
          {articles.map((article) => (
            <ArticleCard
              key={article.uuid}
              article={article}
              onClick={() => onArticleClick(article)}
            />
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: 20,
            zIndex: 1,

            maxHeight: isMobile ? "500px" : "100%",
          }}
        >
          {rootSections.map((section) => (
            <SectionCard
              key={section.uuid}
              icon={section.icon as string}
              title={section.title}
              description={section.description}
              onClick={() => onSectionClick(section)}
            />
          ))}
        </div>
      </div>
    );
  }
);

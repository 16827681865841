import { faCheck, faCopy, faThumbTack } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@hiyllo/ux/button";
import { styled } from "@hiyllo/ux/styled";
import React from "react";
import * as PinMessageBP from "../../../blueprints/admin/conversations/pin-message";
import { client } from "../../../singletons/moopsy-client";
import { Message } from "../../../types/conversations/message";
import { useAlert } from "../../../providers/alert-provider";

interface MessageOptionsProps {
  message: Message;
}

const MessageContainer = styled("div", ({ $theme }) => ({
  position: "absolute",
  top: "-35px",
  left: 0,
  padding: "5px",
  backgroundColor: $theme.midground1,
  color: $theme.foreground,
  display: "flex",
  alignItems: "center",
  borderRadius: "5px",
  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
  zIndex: 500,
  opacity: 0.4,
  gap: "5px",
  ":hover": {
    opacity: 1,
  },
}));

export const MessageOptions: React.FC<MessageOptionsProps> = React.memo(
  ({ message }) => {
    const [copied, setCopied] = React.useState(false);
    const [pinned, setPinned] = React.useState(false);
    const { pushAlert } = useAlert();

    const pinMessageMutation =
      client.useMutation<PinMessageBP.Plug>(PinMessageBP);

    const handleCopy = React.useCallback(() => {
      navigator.clipboard.writeText(message.content);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    }, [message.content]);

    const handlePinMessage = React.useCallback(() => {
      pinMessageMutation
        .call({
          conversationUUID: message.conversationUUID,
          messageUUID: message.uuid,
        })
        .finally(() => {
          setPinned(true);
          setTimeout(() => setPinned(false), 2000); // Reset after 2 seconds
        })
        .catch((e: Error) => {
          pushAlert(e.message as string, "error");
        });
    }, [message.conversationUUID, message.uuid, pinMessageMutation, pushAlert]);

    return (
      <MessageContainer>
        <Button
          onClick={handleCopy}
          label={<FontAwesomeIcon icon={copied ? faCheck : faCopy} />}
          style={{
            borderRadius: 100,
            fontSize: 13,
            height: 30,
            width: 30,
          }}
        />
        {/* <Button
          onClick={() => navigator.clipboard.writeText(content)}
          label={<FontAwesomeIcon icon={faLink} />}
          style={{
            borderRadius: 100,
            fontSize: 13,
            height: 30,
            width: 30,
          }}
        /> */}
        <Button
          onClick={handlePinMessage}
          label={<FontAwesomeIcon icon={pinned ? faCheck : faThumbTack} />}
          style={{
            borderRadius: 100,
            fontSize: 13,
            height: 30,
            width: 30,
          }}
        />
      </MessageContainer>
    );
  }
);

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@hiyllo/ux/theme";
import { styled } from "@hiyllo/ux/styled";
import { ArticleView } from "../article-view";
import * as RetrievePaginatedArticlesBP from "../../../../../../blueprints/knowledge-base/article/retrieve-paginated-articles";
import { client } from "../../../../../../singletons/moopsy-client";
import { useNavigate } from "react-router-dom";

const Container = styled("div", (props) => ({
  margin: "0 auto",
  marginTop: "24px",
  overflowY: "auto",
  width: "100%",
}));

const ExplanatoryText = styled("div", (props) => ({
  backgroundColor: props.$theme.background3,
  borderRadius: "8px",
  padding: "20px",
  marginBottom: "24px",
  color: props.$theme.foreground,
  lineHeight: "1.6",
  margin: "20px 20px",
}));

const LearnArticleViewComponent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [hasArticles, setHasArticles] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [pageSize] = React.useState(15);

  const articlesQuery = client.useQuery<RetrievePaginatedArticlesBP.Plug>(
    RetrievePaginatedArticlesBP,
    { pageNumber, pageSize }
  );

  React.useEffect(() => {
    const fetchArticles = async () => {
      try {
        const allArticles = articlesQuery.data?.articles ?? [];
        if (allArticles.length > 1 && !hasArticles) {
          setHasArticles(true);
        }
      } catch (error) {
        console.error("Failed to load articles", error);
      }
    };
    fetchArticles();
  }, [articlesQuery.data?.articles, hasArticles]);

  return (
    <Container>
      <ExplanatoryText>
        <h2 style={{ marginTop: 0 }}>Lets create your first Article</h2>
        <p>
          Articles allow you to provide detailed information on specific topics
          or sections. Each article can include text, images, and even embedded
          media to help convey your message effectively.
        </p>
        <p>
          <strong>Sections and Articles:</strong> Articles are typically
          organized within sections. Each section can contain multiple articles,
          allowing you to group related content in an easily navigable format.
        </p>
        <p>
          Use the <FontAwesomeIcon icon={faPlus} size="sm" />{" "}
          <strong>Add Article</strong> button below to create your first
          article.
        </p>
      </ExplanatoryText>
      <ArticleView />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: hasArticles ? 0 : 55,
          paddingBottom: hasArticles ? 25 : 55,
        }}
      >
        {hasArticles ? (
          <button
            style={{
              backgroundColor: theme.colorSubtleAccent,
              color: theme.foreground,
              padding: "8px 16px",
              borderRadius: "20px",
              border: "none",
              cursor: "pointer",
              fontSize: "14px",
              transition: "background-color 0.3s ease",
            }}
            onClick={() => {
              navigate("/admin/dashboard/knowledge-base/learn-sections");
            }}
          >
            Continue to Sections
          </button>
        ) : (
          <button
            style={{
              backgroundColor: theme.colorWarning,
              color: theme.foreground,
              padding: "8px 16px",
              borderRadius: "20px",
              border: "none",
              cursor: "pointer",
              fontSize: "14px",
              transition: "background-color 0.3s ease",
            }}
            onClick={() => {
              navigate("/admin/dashboard/learn-sections");
            }}
          >
            Skip, I'll do this later
          </button>
        )}
      </div>
    </Container>
  );
};

export const LearnArticleView = React.memo(LearnArticleViewComponent);

import React, { useState, useEffect } from "react";
import { useTheme } from "@hiyllo/ux/theme";
import { styled } from "@hiyllo/ux/styled";
import * as RetrieveAllSectionsBP from "../../../blueprints/knowledge-base/section/retrieve-all-sections";
import { client } from "../../../singletons/moopsy-client";
import { Section } from "../../../types/knowledge-base/section";
import { Article } from "../../../types/knowledge-base/article";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faMagnifyingGlass,
  faNewspaper,
} from "@fortawesome/pro-solid-svg-icons";

const ModalOverlay = styled("div", (props) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
  backdropFilter: "blur(8px)",
  WebkitBackdropFilter: "blur(8px)",
  color: props.$theme.foreground,
}));

const ModalContainer = styled("div", (props) => ({
  backgroundColor: props.$theme.background2,
  borderRadius: "12px",
  boxShadow: "0 15px 50px rgba(0,0,0,0.2)",
  width: "90%",
  maxWidth: "900px",
  maxHeight: "80%",
  height: "80%",
  display: "flex",
  overflow: "hidden",
}));

const SidePanel = styled("div", (props) => ({
  width: "300px",
  backgroundColor: props.$theme.background3,
  padding: "20px",
}));

const MainContent = styled("div", (props) => ({
  flex: 1,
  padding: "20px",
  overflowY: "auto",
}));

const SearchInput = styled("div", (props) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: props.$theme.background2,
  border: `1px solid ${props.$theme.midground1}`,
  borderRadius: "8px",
  padding: "8px 12px",
  marginBottom: "15px",
}));

const Input = styled("input", (props) => ({
  border: "none",
  outline: "none",
  flex: 1,
  backgroundColor: "transparent",
  color: props.$theme.foreground,
  fontSize: "14px",
  marginLeft: "10px",
}));

const SectionItem = styled("div", (props) => ({
  display: "flex",
  alignItems: "center",
  padding: "10px",
  borderRadius: "6px",
  cursor: "pointer",
  transition: "background-color 0.2s",
}));

const ArticleList = styled("div", (props) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
  gap: "10px",
}));

const ArticleCard = styled("div", (props) => ({
  backgroundColor: props.$theme.midground1,
  borderRadius: "8px",
  padding: "15px",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  marginBottom: "5px",
}));

const CloseButton = styled("button", (props) => ({
  background: props.$theme.colorSubtleAccent,
  border: "none",
  cursor: "pointer",
  color: props.$theme.foreground,
  padding: "8px 12px",
  borderRadius: "8px",
}));

const SectionTitle = styled("div", {
  marginLeft: "8px",
});

const ArticleTitle = styled("div", {
  fontWeight: 500,
});

interface SectionLookupProps {
  isModalOpen: boolean;
  onClose: () => void;
  onSelectSection: (section: Section) => void;
  selectedArticles: Article[];
}

export const SectionLookup: React.FC<SectionLookupProps> = React.memo(
  ({ isModalOpen, onClose, onSelectSection, selectedArticles }) => {
    const theme = useTheme();
    const [sections, setSections] = useState<Section[]>([]);
    const [searchTerm, setSearchTerm] = useState("");

    const getAllSectionsQuery = client.useQuery(RetrieveAllSectionsBP, {});

    useEffect(() => {
      if (getAllSectionsQuery.data?.sections) {
        setSections(getAllSectionsQuery.data.sections);
      }
    }, [getAllSectionsQuery.data]);

    const filteredSections = React.useMemo(
      () =>
        sections
          .filter((section) => section.title.toLowerCase() !== "root")
          .filter((section) =>
            section.title.toLowerCase().includes(searchTerm.toLowerCase())
          ),
      [sections, searchTerm]
    );

    const articlesBySection = React.useMemo(
      () =>
        sections
          .filter((section) =>
            selectedArticles.some(
              (article) => article.parentSectionUUID === section.uuid
            )
          )
          .map((section) => ({
            section,
            articles: selectedArticles.filter(
              (article) => article.parentSectionUUID === section.uuid
            ),
          })),
      [sections, selectedArticles]
    );

    return (
      <>
        {isModalOpen && (
          <ModalOverlay>
            <ModalContainer
              style={{
                width: articlesBySection.length > 0 ? "90%" : "19%",
              }}
            >
              <SidePanel
                style={{
                  borderRight:
                    articlesBySection.length > 0
                      ? `1px solid ${theme.midground1}`
                      : "none",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <SearchInput>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                    <Input
                      type="text"
                      placeholder="Search sections..."
                      value={searchTerm}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setSearchTerm(e.target.value)
                      }
                    />
                  </SearchInput>
                  <div>
                    {filteredSections.map((section) => (
                      <SectionItem
                        key={section.uuid}
                        onClick={() => onSelectSection(section)}
                      >
                        <FontAwesomeIcon icon={faFolder} />
                        <SectionTitle>{section.title}</SectionTitle>
                      </SectionItem>
                    ))}
                  </div>
                </div>
                {articlesBySection.length < 1 && (
                  <CloseButton onClick={onClose}>Close</CloseButton>
                )}
              </SidePanel>
              {articlesBySection.length > 0 && (
                <MainContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                      paddingBottom: "20px",
                    }}
                  >
                    <p style={{ marginTop: "0px" }}>
                      These are Articles that are already linked to a section.
                    </p>
                    {articlesBySection.length > 0 && (
                      <CloseButton onClick={onClose}>Close</CloseButton>
                    )}
                  </div>
                  <ArticleList>
                    {articlesBySection.map(({ section, articles }) => (
                      <div key={section.uuid}>
                        <h4 style={{ marginTop: 0 }}>{section.title}</h4>
                        {articles.map((article) => (
                          <ArticleCard key={article.uuid}>
                            <FontAwesomeIcon icon={faNewspaper} />
                            <ArticleTitle>{article.title}</ArticleTitle>
                          </ArticleCard>
                        ))}
                      </div>
                    ))}
                  </ArticleList>
                </MainContent>
              )}
            </ModalContainer>
          </ModalOverlay>
        )}
      </>
    );
  }
);

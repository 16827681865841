/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { Article } from "../../../types/knowledge-base/article";

export type ParamsType = {
  pageNumber: number;
  pageSize: number;
};
export type ResponseType = {
  articles: Article[];
  totalArticles: number;
};
export const Endpoint = "article/retrieve-paginated-articles";
export const Method = "GET";
export const isPublic = true;
export interface Plug {
  params: ParamsType;
  response: ResponseType;
  method: typeof Method;
  endpoint: typeof Endpoint;
}
export const RateLimitingConfig = {
  calls: 5,
  per: 3000,
};
export const paramsSchema = {"type":"object","properties":{"pageNumber":{"type":"number"},"pageSize":{"type":"number"}},"required":["pageNumber","pageSize"],"$schema":"http://json-schema.org/draft-07/schema#"}
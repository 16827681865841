import { Sidebar } from "@hiyllo/ux/tokyo";
import React from "react";
import { useTenant } from "../../providers/tenant-provider";
import { styled } from "@hiyllo/ux/styled";
import { client } from "../../singletons/moopsy-client";
import * as Blueprint from "../../blueprints/fs/get-image-src";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";

export const LeftSidebarTypography = styled("div", ({ $theme }) => ({
  // width: "calc(50% - 40px)",

  maxHeight: 100,
  objectFit: "contain",
  fontFamily: '"hiyllo", sans-serif',
  fontSize: 20,
  fontWeight: 600,
  color: $theme.foreground,
}));

const LogoContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: 20,
});

export const SupportAdminSidebar = React.memo(function SupportAdminSidebar(
  props: React.PropsWithChildren
): JSX.Element {
  const tenant = useTenant();
  const [logoSrc, setLogoSrc] = React.useState<string | null>(null);

  const getSrcFromFsid = client.useMutation<Blueprint.Plug>(Blueprint);

  React.useEffect(() => {
    if (tenant.appearance.logo) {
      getSrcFromFsid.call({ fsId: tenant.appearance.logo }).then((src) => {
        setLogoSrc(src.imageSrc);
        console.log(src);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Sidebar.Container>
      {props.children}
      <div style={{ flexGrow: 1, height: 0 }} />
      <LogoContainer>
        {logoSrc !== null ? (
          <img width={35} height={35} src={logoSrc} alt="Tenant Logo" />
        ) : (
          <LoadingSpinner />
        )}
        <LeftSidebarTypography>{tenant.appearance.name}</LeftSidebarTypography>
      </LogoContainer>
    </Sidebar.Container>
  );
});

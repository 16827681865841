import { Button } from "@hiyllo/ux/button";
import { IconDisplay } from "@hiyllo/ux/icon-display";
import { Input } from "@hiyllo/ux/input";
import { styled } from "@hiyllo/ux/styled";
import { useTheme } from "@hiyllo/ux/theme";
import React from "react";

interface NewSectionCardProps {
  sectionTitle: string;
  sectionDescription: string;
  selectedIcon: string;
  setSelectedIcon: (icon: string) => void;
  setSectionTitle: (title: string) => void;
  setSectionDescription: (description: string) => void;
  handleCreateSection: () => void;
  setNewSectionCard: (value: boolean) => void;
}

const Container = styled("div", (props) => ({
  backgroundColor: props.$theme.background3,
  borderRadius: "8px",
  padding: "15px",
  marginBottom: "16px",
}));

const Grid = styled("div", {
  display: "grid",
  gap: "16px",
});

const Row = styled("div", {
  display: "flex",
  gap: "15px",
});

const IconInputContainer = styled("div", {
  width: "200px",
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

const Actions = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  gap: "12px",
  marginTop: "24px",
});

export const NewSectionCard: React.FC<NewSectionCardProps> = React.memo(
  (props) => {
    const theme = useTheme();
    return (
      <Container themeOverride={theme}>
        <h3
          style={{
            color: theme.foreground,
            marginBottom: "20px",
            fontSize: "22px",
            marginTop: 0,
          }}
        >
          Create a section
        </h3>
        <Grid>
          <Row>
            <IconInputContainer>
              {props.selectedIcon && (
                <IconDisplay
                  iconName={props.selectedIcon}
                  size="lg"
                  color={theme.foreground}
                />
              )}
              <Input
                label="Icon"
                type="text"
                value={props.selectedIcon}
                placeholder="icon"
                onChange={(e) => props.setSelectedIcon(e.target.value)}
                fullWidth={true}
              />
            </IconInputContainer>
            <Input
              label="Title"
              type="text"
              placeholder="title"
              value={props.sectionTitle}
              onChange={(e) => props.setSectionTitle(e.target.value)}
              fullWidth={true}
            />
          </Row>
          <Input
            label="Description"
            placeholder="description"
            value={props.sectionDescription}
            onChange={(e) => props.setSectionDescription(e.target.value)}
            multiline={true}
            fullWidth={true}
          />
        </Grid>
        <Actions>
          <Button
            label="Cancel"
            onClick={() => props.setNewSectionCard(false)}
            style={{ background: theme.midground1 }}
          />
          <Button
            label="Create"
            onClick={props.handleCreateSection}
            style={{ background: theme.colorSubtleAccent }}
          />
        </Actions>
      </Container>
    );
  }
);

import React from "react";
import { styled } from "@hiyllo/ux/styled";
import { useTheme } from "@hiyllo/ux/theme";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { authExtension, client } from "../../../../../singletons/moopsy-client";
import * as RetrieveArticleBP from "../../../../../blueprints/knowledge-base/article/retrieve-article";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { EditorV2 } from "@hiyllo/editor/main";
import { DocumentContentsV2 } from "@hiyllo/editor/v2/types";
import { NeedMoreHelp } from "../components/need-more-help";

const ArticleContainer = styled("div", ({ $theme }) => ({
  backgroundColor:
    $theme.colorScheme === "light"
      ? "rgba(255, 255, 255, 0.5)"
      : "rgba(0, 0, 0, 0.25)",
  color: $theme.foreground,
  padding: 10,
  borderRadius: 7.5,
  whiteSpace: "pre-wrap",
  backdropFilter: "blur(10px)",
}));

export const ArticleView = React.memo(function Article(): JSX.Element {
  const params = useParams();
  const theme = useTheme();

  if (params.uuid == null) {
    throw new Error("Article UUID is required");
  }

  const articleQuery = client.useQuery<RetrieveArticleBP.Plug>(
    RetrieveArticleBP,
    { uuid: params.uuid }
  );
  const navigate = useNavigate();
  const authState = authExtension.useAuthState();

  React.useEffect(() => {
    const handleFocus = () => {
      if (authState === null) {
        window.location.reload();
      }
    };

    window.addEventListener("focus", handleFocus);
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [authState, navigate]);

  if (articleQuery.isLoading) {
    return <div />;
  }

  return (
    <div>
      {articleQuery.isLoading ? null : articleQuery.isError ? null : (
        <>
          <div style={{ paddingTop: 20, paddingBottom: 20 }}>
            <div style={{ cursor: "pointer", fontSize: 25, marginBottom: 5 }}>
              <FontAwesomeIcon
                icon={faArrowLeft}
                onClick={() =>
                  navigate(
                    "/section/" + articleQuery.data.article.parentSectionUUID
                  )
                }
              />
            </div>
            <div>
              Last Updated:{" "}
              {moment(articleQuery.data.article.updatedAt).format(
                "MMM Do, YYYY"
              )}
            </div>
            <div
              style={{
                color: theme.foreground,
                fontSize: 37.5,
                fontWeight: "bold",
              }}
            >
              {articleQuery.data.article.title}
            </div>
          </div>
          <ArticleContainer>
            <EditorV2
              initialContents={
                articleQuery.data.article.content as DocumentContentsV2
              }
              onValueChanged={() => {
                //
              }}
              readOnly
            />
          </ArticleContainer>
          <NeedMoreHelp />
        </>
      )}
    </div>
  );
});
